<template>
  <div>
    <div id="content">
      <template v-if="$route.path === '/news'">
        <Splash
          :bannerImage="page[6].acf.banner_image"
        />
      </template>
      <template v-else>
        <Splash
          :bannerImage="page[0].acf.banner_image"
        />
      </template>
      <div class="ourContainer">
        <div class="wp">
          <div class="TitleandParagraph">
            <h1 class="newsHead">News</h1>
            <template v-if="$route.path === '/news'">
              <p class="newsSection" v-html="page[6].acf.news_section"></p>
            </template>
            <template v-else>
              <p class="newsSection" v-html="page[0].acf.news_section"></p>
            </template>
          </div>
          <b-row class="filterSection">
            <!-- <b-col v-if="disPage === false" sm="12" lg="4">
              <div>
                <h1 class="newsDropdown">FILER BY CATEGORY</h1>
                <b-dropdown id="dropdown-1" class="m-md-2">
                  <b-dropdown-item
                    v-for="(opt, index) in options"
                    :key="index"
                    v-on:click="pickStat"
                    :title="opt.text"
                    selected="All"
                    >{{ opt.text }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col> -->
            <b-col v-if="disPage === false" sm="12" lg="4">
              <h1 class="newsDropdown SearchText">SEARCH</h1>
              <b-input-group class="mt-3">
                <b-form-input v-model="search" placeholder="Search"></b-form-input>
                <b-input-group-append>
                  <b-button
                    class="search"
                    :to="{
                      name: 'search',
                      query: {
                        id: search,
                      },
                    }"
                    ><font-awesome-icon class="icon" :icon="['fa', 'search']"
                  /></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <template v-if="this.$route.path === '/news'">
              <b-col sm="12" lg="8">
                <b-input-group class="mt-3 selectNextCheck">
                  <b-form-checkbox-group
                    v-model="pick"
                    :options="options"
                    plain
                  ></b-form-checkbox-group>
                </b-input-group>
              </b-col>
            </template>
          </b-row>
          <News :cat="Cat" :newsPage="newsPage" />
        </div>
      </div>
    </div>   
    <Footer /> 
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
import News from "@/components/news.vue";
import Splash from "@/components/splash.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "news",
  components: { 
    News,
    Splash,
    Footer
  },
  data() {
    return {
      page: [],
      title: '',
      description: '',
      parentId: "",
      newsPage: true,
      pick: "",
      search:'',
      options: [
        { value: "", text: "All" },
        { value: "swimming", text: "Swimming" },
        { value: "para-swimming", text: "Para Swimming" },
        { value: "diving", text: "Diving" },
        { value: "open-water", text: "Open Water" },
        { value: "water-polo", text: "Water Polo" },
        { value: "masters", text: "Masters" },
        { value: "artistic-swimming", text: "Artistic Swimming" },
        { value: "uncategorized", text: "General" },
      ],
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  computed: {
    Cat: function(cat, rn) {
      if (this.pick === "") {
        var sp = this.$route.name.split("-");
        if (sp.length > 2) {
          rn = [sp[0] + "-" + sp[1]];
        } else {
          rn = sp;
        }
        if (rn[0] === "news") {
          cat = "none";
        } else {
          cat = rn[0];
        }
      } else {
        cat = this.pick;
      }
      return cat;
    },
    route: function() {
      var r = this.$route.fullPath.split("/");
      if (r[0] === "") {
        r[0] = "home";
      }
      return r;
    },
    disPage:function(dis){
      if (this.$route.fullPath.includes("discipline")){
        dis = true
      }
      else {dis = false}
      return dis
    }
  },
  methods: {
    routeTitle(item) {
      var low = item;
      var rep = low.replace(/-/g, " ");
      return rep;
    },
    async Dis() {
      var rn = this.Cat;
      if (rn === "none") {
        apiCall
          .wpInfo("wp/v2/pages?slug=news&per_page=100")
          .then(({ data }) => {
            this.page = data;
            this.loading = false;
            this.title = data[0].title.rendered;
            this.description = data[0].yoast_head_json.og_description;
          })
          .catch((error) => {
            console.log(error);
            throw error;
          });
      } else {
        try {
          // First call, await for call to return
          let first_call = await apiCall.wpInfo("wp/v2/pages?slug=" + rn);
          this.parentId = first_call.data[0].id;
          this.loading = false;
          // Second call, await for call to return
          let second_call = await apiCall.wpInfo(
            "wp/v2/pages?slug=news" + "&parent=" + this.parentId
          );
          this.page = second_call.data;
          this.title = second_call.data[0].title.rendered;
          this.description = second_call.data[0].yoast_head_json.og_description;
          this.loading = false;
        } catch (error) {
          console.log("error in await calls");
          console.log(error);
          throw error;
        }
      }
    },
  },
  mounted() {
    this.Dis();
  },
};
</script>

<style scoped>
.input-group.mt-3.selectNextCheck {
  display: inline;
}
.input-group.mt-3.selectNextCheck > div {
  margin-top: 40px;
}
::v-deep figure.wp-block-image.size-large > img {
  height: auto;
}
.TitleandParagraph {
  padding-left: 27px;
  padding-right: 27px;
  padding-bottom: 30px;
}
h1.newsHead {
  padding-bottom: 20px;
  font-family: GT-Haptik-Medium;
  color: #1f1545;
  font-size: 2.5rem;
}
p.newsSection {
  color: #1f1545;
}
::v-deep.DateGroup {
  padding-right: 5px;
}
::v-deep.DateGroup > button {
  margin-left: -3px;
}
.SearchText {
  padding-left: 0em!important;
}
.filterSection {
  padding-left: 22px;
  padding-right: 30px;
}
::v-deep .btn-secondary {
  color: #201546;
  background-color: #dadada;
  border-color: #dadada!important;
  border-radius: 0px;
}
.m-md-2 {
  margin: 0.5rem !important;
  width: 100%;
}
.newsDropdown {
  color: #1f1545 !important;
  padding-bottom: 10px;
  padding-top: 5px;
  font-weight: 100;
  font-size: 10pt;
  padding-left: 0.5em;
}
#content {
  padding-left: 20%;
}
.overlay {
  background-color: #201546;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/HomePage_Overlay_1@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.splash {
  opacity: 0.46;
  height: 603px;
  object-fit: cover;
}
.title {
  position: absolute;
  top: 277px;
  width: 100%;
  text-align: center;
  height: 75px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000058;
  font-weight: bold;
  font-size: 3.5rem;
}
.route {
  display: inline-flex;
}
.route > li > a {
  padding: 0.5rem 0;
  text-transform: uppercase;
  font-weight: bold;
}
.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
::v-deep .subTitle {
  padding: 30px 0 0 0;
  color: #201546;
  text-transform: capitalize;
  font-weight: bold;
}
.search {
  background-color: #e8e7ec;
}
.form-control::placeholder {
  color: #201546;
  opacity: 1;
}
.form-control {
  height: 38px;
  background-color: #e8e7ec;
  border-radius: 0px;
}
.custom-select {
  height: 56px;
}
.hide0 {
  display: none;
}
.nav-item > a {
  color: #009fe3;
}
.wp {
  padding-top: 50px;
}
.icon {
  /* vertical-align: -0.8em; */
}
@media only screen and (max-width: 768px) {
  .search {
    background-color: #e50451;
    border-radius: 0px 33px 33px 0px;
    width: 138px;
    height: 56px;
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  #content {
    padding-left: 0;
  }
  ::v-deep .wp {
    padding-top: 10px!important;
  }
  ::v-deep  .ourContainer {
    max-width: none;
    margin: 0px auto;
    padding: 0 0px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media only screen and (max-width: 500px) {
  .filterSection {
    padding-left: 0px;
    padding-right: 0px;
  }
  .TitleandParagraph {
    padding-left: 0px;
    padding-right: 0px;
  }
  .subTitle {
    font-weight: bold;
    font-size: 1.3rem;
    margin-top: 6px;
  }
}
</style>
