<template>
  <div>
    <b-row>
      <!-- <template v-if="filterEvents.length === 0">
        <b-col>
          <h2 class="noEvent">
            There are currently no events under the discipline
          </h2>
        </b-col>
      </template> -->
      <b-col
        class="eve"
        lg="6"
        md="12"
        cols="12"
        v-for="(event, index) in events"
        :key="index"
      >
        <b-card no-body class="overflow-hidden">
          <b-row no-gutters>
            <b-col md="8">
              <b-card-body>
                <b-card-text>
                  <h3 class="dates" :class="itemLink(event.acf.discipline[0])">
                    {{ event.acf.dates }}
                  </h3>
                  <h3 class="title">
                    {{ event.acf.title }}
                  </h3>
                  <h3 class="venue">
                    {{ event.acf.venue }}
                  </h3>
                </b-card-text>
              </b-card-body>
            </b-col>
            <b-col md="4">
              <b-card-img
                :src="event.acf.image"
                alt="Image"
                class="rounded-0 eventImg"
              ></b-card-img>
            </b-col>
          </b-row>
        </b-card>
        <template v-if="discipline === 'major-events' || event.acf.major_event === true">
          <b-nav-item :to="'/coaching-and-teaching/course-calendar/' + event.slug">
            <div
              class="titleBtn"
              :class="itemLink(event.acf.discipline[0]) + 'Back'"
            >
              <span class="db" v-if="discipline === 'none'"
                >SEE ALL {{ event.acf.discipline[0] }} EVENTS</span
              >
              <span v-else>EVENT INFORMATION</span>
            </div>
          </b-nav-item>
        </template>
        <template v-else>
          <b-nav-item
            :to="'/coaching-and-teaching/course-calendar/' + event.slug"
          >
            <div
              class="titleBtn"
              :class="itemLink(event.acf.discipline[0]) + 'Back'"
            >
              <span class="db" v-if="discipline === 'none'"
                >SEE ALL {{ event.acf.discipline[0] }} EVENTS</span
              >
              <span v-else>EVENT INFORMATION</span>
            </div>
          </b-nav-item>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "courseupComing",
  props: ["discipline", "total", 'pick'],
  data() {
    return {
      events: [],
    };
  },
  methods: {
    itemLink(item) {
      var low = item.toLowerCase();
      var rep = low.replace(/ /g, "-");
      return rep;
    },
  },
  computed: {
    filterEvents: function(eve, set) {
      if (this.total === "all") {
        if (this.discipline === "none" || this.discipline === "events") {
          eve = this.events;
        } else if (this.discipline === "major-events") {
          eve = this.events.filter((dis) => dis.acf.major_event === true);
        } else if (this.discipline === "national-events" && this.pick !== "") {
          eve = this.events.filter((dis) => dis.acf.national_events === true && this.pick === dis.acf.discipline[0]);
        }else if (this.discipline === "national-events") {
          eve = this.events.filter((dis) => dis.acf.national_events === true);
        } else {
          eve = this.events.filter((dis) =>
            dis.acf.discipline.includes(this.discipline)
          );
        }
      } else {
        if (this.discipline === "none" || this.discipline === "events") {
          set = this.events;
        } else {
          set = this.events.filter((dis) =>
            dis.acf.discipline.includes(this.discipline)
          );
        }
        eve = set.slice(0, 2).reverse();
      }
      return eve;
    },
  },
  mounted() {
    apiCall
      .wpInfo("wp/v2/courseevents?per_page=100")
      .then(({ data }) => {
        this.events = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.eventImg {
  height: 313px;
  object-fit: cover;
}
.eve {
  padding-top: 60px;
}
.card {
  background-color: #201546;
  color: #fff;
  border: 0px;
  border-radius: 0;
}
.title {
  font-size: 24px;
  font-weight: bold;
  padding: 20px 0;
}
.dates {
  font-size: 18px;
  font-weight: bold;
  padding: 20px 0;
}
.venue {
  font-size: 16px;
  padding: 10px 0;
}
.swimming {
  color: #009fe3;
}
.para-swimming {
  color: #afca0b;
}
.water-polo {
  color: #349980;
}
.masters {
  color: #ff9901;
}
.artistic-swimming {
  color: #f80240;
}
.diving {
  color: #6ec9df;
}
.open-water {
  color: #5c439c;
}
.swimmingBack {
  background-color: #009fe3;
}
.para-swimmingBack {
  background-color: #afca0b;
}
.water-poloBack {
  background-color: #349980;
}
.mastersBack {
  background-color: #ff9901;
}
.artistic-swimmingBack {
  background-color: #f80240;
}
.divingBack {
  background-color: #6ec9df;
}
.open-waterBack {
  background-color: #5c439c;
}
.titleBtn {
  height: 66px;
  border-radius: 0px 33px 33px 0px;
  font-size: 16px;
  font-weight: bold;
  font-weight: bold;
  color: #fff;
  margin-top: -33px;
  position: absolute;
  padding: 20px;
  text-transform: uppercase;
  padding-right: 75px;
  -webkit-transition: padding-right 0.5s;
  transition: padding-right 0.5s;
}
.titleBtn:hover {
  padding-right: 175px;
}
.titleBtn > .nav-item > a {
  padding: 0;
  color: #fff;
}
.ourContainer {
  max-width: 1350px;
  margin: 0 auto;
}
.nav-item > a {
  padding: 0;
}
.noEvent {
  color: #bfbfbf;
  margin-left: -30px;
}

@media only screen and (max-width: 500px) {
  .title {
    font-size: 24px;
    font-weight: bold;
    padding: 20px 0;
    padding-top: 0px;
  }
}
</style>
