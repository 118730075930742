<template>
  <div>
    <div id="content">
      <Splash
        :bannerImage="page[0].acf.banner_image"
      />
      <div class="ourContainer">
        <!-- <Route /> -->
        <h2 class="titleHeader">{{this.page[0].title.rendered}}</h2>
        <b-row>
          <b-col lg="9">
            <div class="ourContainer" v-for="(job, index) in jobs" :key="index">
              <template v-if="job.acf.featured_job === true"> 
                <b-row class="featuredJob">
                  <b-col lg='3' md='3' cols='12' class="disCol">
                      <b-img :src="job.acf.image"></b-img>
                  </b-col>
                  <b-col>
                    <p class='standard'>
                      {{ dateTime(job.date) }}
                    </p>
                    <p class='title'>
                      {{ job.acf.title }}
                    </p>
                    <p class='specItem'>
                      <span class='specTitle'>Organisation: </span><span class='standard'>{{ job.acf.organisation }}</span>
                    </p>
                    <p class='specItem'>
                      <span class='specTitle'>Location: </span><span class='standard'>{{ job.acf.location }}</span>
                    </p>
                    <p class='specItem'>
                      <span class='specTitle'>Job Type: </span><span class='standard'>{{ job.acf.job_type }}</span>
                    </p>
                    <p class='specItem'>
                      <span class='specTitle'>Hours: </span><span class='standard'>{{ job.acf.hours }}</span>
                    </p>
                    <p class='specItem'>
                      <span class='specTitle'>Salary: </span><span class='standard'>{{ job.acf.salary }}</span>
                    </p>
                    <p class='specItem'>
                      <span class='specTitle'>Closing Date: </span><span class='standard'>{{ job.acf.closing_date }}</span>
                    </p>
                  </b-col>
                  <b-col lg='2' md='2' cols='12'>
                      <b-button :to="'/jobs/' + job.slug" class='apply'>View Job</b-button>
                  </b-col>
                </b-row>
              </template>
            </div>
            
            <div class="ourContainer" v-for="(job, index) in jobs" :key="index">
              <template v-if="job.acf.job_done === false"> 
                <template v-if="job.acf.featured_job === false">
                  <b-row>
                    <b-col lg='3' md='3' cols='12' class="disCol">
                        <b-img :src="job.acf.image"></b-img>
                    </b-col>
                    <b-col>
                      <p class='standard'>
                        {{ dateTime(job.date) }}
                      </p>
                      <p class='title'>
                        {{ job.acf.title }}
                      </p>
                      <p class='specItem'>
                        <span class='specTitle'>Organisation: </span><span class='standard'>{{ job.acf.organisation }}</span>
                      </p>
                      <p class='specItem'>
                        <span class='specTitle'>Location: </span><span class='standard'>{{ job.acf.location }}</span>
                      </p>
                      <p class='specItem'>
                        <span class='specTitle'>Job Type: </span><span class='standard'>{{ job.acf.job_type }}</span>
                      </p>
                      <p class='specItem'>
                        <span class='specTitle'>Hours: </span><span class='standard'>{{ job.acf.hours }}</span>
                      </p>
                      <p class='specItem'>
                        <span class='specTitle'>Salary: </span><span class='standard'>{{ job.acf.salary }}</span>
                      </p>
                      <p class='specItem'>
                        <span class='specTitle'>Closing Date: </span><span class='standard'>{{ job.acf.closing_date }}</span>
                      </p>
                    </b-col>
                    <b-col lg='2' md='2' cols='12'>
                        <b-button :to="'/jobs/' + job.slug" class='apply'>View Job</b-button>
                    </b-col>
                  </b-row>
                </template>
              </template>
            </div>
          </b-col>
          <b-col lg="3">
            <div class="downloadsLink">
              <h4 class="sideTitle">
                <template>
                  <h4 class="sideTitle downTop">
                    Other Jobs
                    <hr class="hrLine">
                  </h4>
                </template>
              </h4>
              <SideBarBlock
                v-for="(link, index) in page[0].acf.useful_links"
                :key="index"
                type="link"
                :title="link.title"
                :url="link.url"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </div>  
    <Footer />  
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
// import Route from "@/components/route.vue";
import SideBarBlock from "@/components/sidebarBlock.vue";
import Splash from "@/components/splash.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "jobs",
  components: {
    Splash, 
    Footer, 
    SideBarBlock 
  },
  data() {
    return {
      page: [],
      parentId: "",
      title: '',
      description: '',
      jobs: [],
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  methods: {
    dateTime(value) {
      var dateOG = value.split("T");
      var splitDate = dateOG[0].split("-");
      var newDate = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0];
      return newDate;
    },
    async Dis() {
      try {
        // First call, await for call to return
        let first_call = await apiCall.wpInfo("wp/v2/pages?slug=jobs");
        this.page = first_call.data;
        this.title = first_call.data[0].title.rendered;
        this.description = first_call.data[0].yoast_head_json.og_description;
        this.loading = false;
        // Second call, await for call to return
        let second_call = await apiCall.wpInfo("wp/v2/jobs?per_page=100");
        this.jobs = second_call.data;
        this.loading = false;
      } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
      }
    },
  },
  mounted() {
    this.Dis();
  },
};
</script>

<style scoped>
.removeLine {
  border-top: 0px solid #ccccd2!important;
  padding: 0px!important;
}
li.nav-item.linkText {
  line-height: 18px;
}
hr.hrLine {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  border-top: 1px solid #1f1545;
}
h4.sideTitle.downTop {
  margin-top: -10px;
}
::v-deep .sideTitle {
  color: #201546;
  font-size: 17px;
  font-weight: 100;
  padding-bottom: 20px;
  padding: 15px 0px 20px 0;
  padding-top: 10px;
  font-family: GT-Haptik-Medium;
  margin-bottom: -15px;
}
.downloadsLink {
  background-color: #cbebf9;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  border-radius: 5px;
  padding-right: 10px;
  margin-top: 40px;
}
.featuredJob {
  background: #98d7f2;
  color: #000;
}
h2.titleHeader{
  padding-bottom: 40px;
  /* font-weight: bold; */
  font-family: GT-Haptik-Medium;
  color: #1f1545;
  font-size: 2.5rem;
}
a.btn.apply.btn-secondary {
  top: 35%;
  position: relative;
}
#content {
  padding-left: 20%;
}
.disCol > .nav-item > .nav-link {
  padding: 0;
}

.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.apply{
  display: inline-block;
  padding: 0.3333em 0.8333em;
  background: #201646;
  color: #ffffff;
  min-height: 34px;
  border-radius: 0;
}
.standard{
  font-size: 14px;
}
.title{
  font-size: 1.3889em;
  margin-bottom: 12px;
  color: #201546;
  font-weight: bold;
}
.specTitle{
  color: #201546;
  font-weight: bold;
}
.specItem{
  margin-bottom: .5rem;
}
.row{
  border-top: 1px solid #ccccd2;
  padding-top: 15px;
  padding-bottom: 15px;
  /* border-bottom: 1px solid #ccccd2; */
}
.row:nth-child(odd){
    /* background-color: #f0f1f1; */
}
@media only screen and (max-width: 990px) {
  #content {
    padding-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  a.btn.apply.btn-secondary {
    top: 0px;
    position: inherit;
    width: 100%;
    border-radius: 15px;
    margin-top: 30px;
  }
   .ourContainer {
    max-width: none;
    margin: 0px auto;
    padding: 0 0px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .title {
    font-size: 1.3889em;
    margin-bottom: 12px;
    color: #201546;
    font-weight: bold;
    margin-bottom: 30px;
  }
}
</style>
