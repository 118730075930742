<template>
  <div>
    <div id="content">
      <Splash
        :bannerImage="page[0].acf.banner_image"
      />
      <div class="ourContainer">
        <!-- <Route /> -->
        <div class="wp">
          <b-row>
            <b-col
              cols="12"
              :class="{ 'col-lg-9 col-md-9': page[0].acf.right_sidebar === true }"
            >
              <!-- This is Wordpress rendered content for the page -->
                <span class="wpContent" v-html="page[0].content.rendered"></span>
              <!-- End is Wordpress rendered content for the page -->

              <!-- FAQs -->
              <template v-if="page[0].acf.faq === true">
                <div class="accordion" role="tablist">
                  <b-card
                    no-body
                    class="mb-1"
                    v-for="(faq, index) in page[0].acf.faq_list"
                    :key="index"
                  >
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button
                        block
                        v-b-toggle="'faq' + index"
                        :id="index"
                        class="faqQ"
                        >{{ faq.question }}<span class="arrow"></span
                      ></b-button>
                    </b-card-header>
                    <b-collapse
                      :id="'faq' + index"
                      :accordion="'faq' + index"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-text>{{ faq.answer }}</b-card-text>
                        <b-card-text>{{ text }}</b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </template>
              <!-- End FAQs -->

              <!-- Hall of Fame -->
              <template v-if="$route.name === 'hall-of-fame'">
                <HallOfFame />
              </template>
              <!-- End Hall of Fame -->

              <!-- Find A Club -->
              <template v-if="$route.name === 'find-a-club'">
                <b-embed
                  type="embed"
                  aspect="1by1"
                  src="https://dev-scotswim-full.ocs-software.com/wp-content/themes/twentytwentyone/club_finder/ClubTest.html"
                ></b-embed>
              </template>
              <!-- End Find A Club -->

              <!-- Event Info for Compete Pages -->
              <template v-if="$route.name === 'compete'">
                <div class="bottom">
                  <UpComing discipline="major-events" total="all" />
                </div>
              </template>
              <!-- End Event Info for Compete Pages -->
            </b-col>

            <!-- Right Side Bar -->
            <template v-if="page[0].acf.right_sidebar === true">
              <b-col>
                <!-- Ads -->
                <template v-if="page[0].acf.ad_links.length">
                  <SideBarBlock
                    v-for="(link, index) in page[0].acf.ad_links"
                    :key="index"
                    type="ad"
                    :image="link.image"
                    :url="link.url"
                  />
                </template>
                <!-- End Ads -->

                <!-- Downloads -->
                <template v-if="$route.path === '/our-sports/swimming/records'">
                  <template v-if="page[1].acf.downloads.length">
                    <div class="downloadsLink">
                      <h4 class="sideTitle">
                        <template v-if="page[1].acf.downloads_header_text == true ">
                          <h4 class="sideTitle downTop">
                            {{page[1].acf.downloads_header_text}}
                            <hr class="hrLine">
                          </h4>
                        </template>
                        <template v-else>
                          <h4 class="sideTitle downTop">
                            Downloads
                            <hr class="hrLine">
                          </h4>
                        </template>
                      </h4>
                      <SideBarBlock
                        v-for="(link, index) in page[1].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>
                <template v-else-if="$route.path === '/our-sports/para-swimming/records'">
                  <template v-if="page[3].acf.downloads.length">
                    <div class="downloadsLink">
                      <template v-if="page[3].acf.downloads_header_text == true ">
                        <h4 class="sideTitle downTop">
                          {{page[3].acf.downloads_header_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle downTop">
                          Downloads
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[3].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>
                <template v-else-if="$route.path === '/our-sports/masters/records'">
                  <template v-if="page[2].acf.downloads.length">
                    <div class="downloadsLink">
                      <template v-if="page[2].acf.downloads_header_text == true ">
                        <h4 class="sideTitle downTop">
                          {{page[2].acf.downloads_header_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle downTop">
                          Downloads
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[2].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>
                <template v-else>
                  <template v-if="page[0].acf.downloads.length">
                    <div class="downloadsLink">
                      <template v-if="page[0].acf.downloads_header_text == true ">
                        <h4 class="sideTitle downTop">
                          {{page[0].acf.downloads_header_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle downTop"> Downloads 
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[0].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>                  

                <!--End Downloads -->

                <!-- URL Links -->
                <template v-if="page[0].acf.useful_links.length">
                  <div class="usefulLinks">
                    <template v-if="page[0].acf.usefull_links_text == true">
                      <h4 class="sideTitle top">
                        {{page[0].acf.usefull_links_text}}
                        <hr class="hrLine">
                      </h4>
                    </template>
                    <template v-else>
                      <h4 class="sideTitle top">
                        Useful Links
                        <hr class="hrLine">
                      </h4>
                    </template>
                    <SideBarBlock
                      v-for="(link, index) in page[0].acf.useful_links"
                      :key="index"
                      type="link"
                      :title="link.title"
                      :url="link.url"
                    />
                  </div>
                </template>                  

                <!-- End URL Links -->
              </b-col>
            </template>
            <!-- End Right Side Bar -->
          </b-row>
        </div>
      </div>

      <!-- Sign Up Banner -->
      <!-- <template v-if="page[0].acf.news_letter_sign_up === true">
        <div class='top'>
          <SignUp />
        </div>
      </template> -->
      <!--End Sign Up Banner -->
    </div>  
    <Footer />  
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
import SideBarBlock from "@/components/sidebarBlock.vue";
import HallOfFame from "@/components/hallOfFame.vue";
// import Route from "@/components/route.vue";
import Splash from "@/components/splash.vue";
// import SignUp from "@/components/signUp.vue";
import UpComing from "@/components/upComing.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "mainPages",
  props: ["slug"],
  components: { SideBarBlock, HallOfFame, Splash, UpComing, Footer },
  data() {
    return {
      page: [],
      title: '',
      description: '',
    };
  },
  methods: {
    itemLink(item) {
      var low = item.toLowerCase();
      var rep = low.replace(/ /g, "-");
      return rep;
    },
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  async mounted() {
    try {
      // First call, await for call to return
      let second_call = await apiCall.wpInfo("wp/v2/pages?slug=" + this.slug);
      this.page = second_call.data;
      this.title = second_call.data[0].title.rendered;
      this.description = second_call.data[0].yoast_head_json.og_description;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
};
</script>

<style scoped>
::v-deep .wpContent > h1 > strong{
  font-weight: 500;
}
::v-deep .wpContent > h1 {
  color: #1f1545;
  padding-bottom: 20px;
  font-family: GT-Haptik-Medium;
  font-size: 2.5rem;
}
li.nav-item.linkText {
  line-height: 18px;
}
hr.hrLine {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  border-top: 1px solid #1f1545;
}
h4.sideTitle.downTop {
  margin-top: -10px;
}
.downloadsLink {
  background-color: #cbebf9;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  border-radius: 5px;
  padding-right: 10px;
  margin-top: 10px;
}
.usefulLinks {
  background-color: #cbebf9;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
  margin-top: 10px;
  border-radius: 5px;
  padding-right: 10px;
}
#content {
  padding-left: 20%;
}
.overlay {
  background-color: #201546;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/HomePage_Overlay_1@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.splash {
  opacity: 0.46;
  height: 603px;
  object-fit: cover;
}
.title {
  position: absolute;
  top: 277px;
  width: 100%;
  text-align: center;
  height: 75px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000058;
  font-weight: bold;
  font-size: 3.5rem;
}
.route {
  display: inline-flex;
}
.route > li > a {
  padding: 0.5rem 0;
  text-transform: uppercase;
  font-weight: bold;
}
.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.wp {
  padding-top: 50px;
}
h2 {
  padding: 50px 0 0 0;
  color: #201546;
  margin-bottom: -10px;
}
::v-deep .wpContent > h2 {
  padding: 30px 0;
  color: #201546;
  font-size: 24px;
  font-weight: 500;
}
::v-deep .wpContent > p {
  color: #201546;
  font-size: 16px;
}
::v-deep .wpContent > h2 > strong{
  font-weight: 500!important;
}
::v-deep .wpContent > figure {
  padding-top: 20px;
}
::v-deep img {
  object-fit: cover;
}
.faqQ {
  background-color: transparent;
  color: #009fe3!important;
  font-style: italic;
  border: 0;
  text-align: left;
}
.arrow {
  border: solid #009fe3;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  right: 10px;
  top: 15px;
}

.nav-item > a {
  color: #009fe3;
}
::v-deep .wpContent > ul > li {
  list-style-type: disc;
  color: #707070;
}
.hide0 {
  display: none;
}
::v-deep .sideTitle {
  color: #201546;
  font-size: 17px;
  font-weight: 100;
  padding-bottom: 20px;
  padding: 15px 0px 20px 0;
  padding-top: 20px;
  font-family: GT-Haptik-Medium;
  margin-bottom: -15px;
}
.route > li:first-child > a {
  text-decoration: underline;
}
.route > li:nth-child(2) > a {
  text-decoration: underline;
}
::v-deep .wpContent > p > a {
  color: #009fe3;
}
::v-deep table {
  width: 100%;
}
::v-deep th {
  background: #009fe3 0% 0% no-repeat padding-box;
  color: #fff;
  padding: 20px 16px 19px 21px;
}
::v-deep td {
  border: 4px solid #fff;
  padding: 17px 18px 17px 21px;
  font-size: 14px;
  color: #707070;
  width: 7%;
}
::v-deep .wpContent > ul > li > a {
  color: #009fe3;
}
::v-deep tr {
  background-color: #f7f7f7;
}
.top {
  padding-top: 50px;
}
.bottom {
  padding-bottom: 30px;
}

@media only screen and (max-width: 768px) {
  ::v-deep .wpContent > figure {
    padding-top: 20px;
    overflow: scroll;
  }
}
@media only screen and (max-width: 990px) {
  #content {
    padding-left: 0;
  }
  ::v-deep .wp {
    padding-top: 10px!important;
  }
}
</style>
