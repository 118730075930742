<template>
  <b-row id='benefits'>
    <b-col class="pink">
      <b-row>
        <b-col lg="3">
          <div class="hwrap">
            <b-img
              class="heart"
              src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Health_Heart@2x.png"
            ></b-img>
          </div>
        </b-col>
        <b-col>
          <div class="healthContent">
            <h2 class="title">
              The Health Benefits of {{ page.title.rendered }}
            </h2>
            <span class="text" v-html="page.acf.health_benefits_text"></span>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "health",
  props: ["page"],
};
</script>

<style scoped>
#benefits{
  padding: 50px 0;
}
.pink {
  /* background-image: url("https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Rectangle-201@2x.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #e50651;
  border-radius: 165px;
}
.hwrap {
  width: 100%;
  text-align: center;
  position: relative;
  top: 65px;
}

.heart {
  width: 100px;
  margin: auto;
}
.title {
  color: #fff;
  font-size: 30px;
  padding-bottom: 20px;
}
::v-deep .text > p {
  color: #fff;
  font-size: 14px;
}
.healthContent {
  padding: 67px 75px 67px 0;
}
@media only screen and (max-width: 990px) {
  .healthContent {
    padding: 67px 40px 67px 40px;
  }
  .title {
    color: #fff;
    font-size: 30px;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;
  }
  .pink {
    border-radius: 50px;
  }
}
</style>
