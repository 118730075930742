<template>
  <div v-if="page[0].acf.right_sidebar === true">
    <h2 class="headerTitle">
      Board Members
    </h2>
    <b-row>
      <b-col lg="4" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Board Members'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Council Members
    </h2>
    <b-row>
      <b-col lg="4" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Council Members'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Chief Executive Officer
    </h2>
    <b-row>
      <b-col lg="4" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Chief Executive Officer'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Aquatics Team
    </h2>
    <b-row>
      <b-col lg="4" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Aquatics Team'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Stronger Clubs
    </h2>
    <b-row>
      <b-col lg="4" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Stronger Clubs'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Supporting Athletes
    </h2>
    <b-row>
      <b-col lg="4" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Supporting Athletes'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Aquatics Partnerships
    </h2>
    <b-row>
      <b-col lg="4" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Aquatics Partnerships'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      People Development
    </h2>
    <b-row>
      <b-col lg="4" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'People Development'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Education Team
    </h2>
    <b-row>
      <b-col lg="4" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Education Team'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Finance Team
    </h2>
    <b-row>
      <b-col lg="4" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Finance Team'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Marketing Team
    </h2>
    <b-row>
      <b-col lg="4" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Marketing Team'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Services Team
    </h2>
    <b-row>
      <b-col lg="4" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Services Team'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <h2 class="headerTitle">
      Board Members
    </h2>
    <b-row>
      <b-col lg="2" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Board Members'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Council Members
    </h2>
    <b-row>
      <b-col lg="2" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Council Members'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Chief Executive Officer
    </h2>
    <b-row>
      <b-col lg="2" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Chief Executive Officer'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Aquatics Team
    </h2>
    <b-row>
      <b-col lg="2" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Aquatics Team'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Stronger Clubs
    </h2>
    <b-row>
      <b-col lg="2" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Stronger Clubs'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Supporting Athletes
    </h2>
    <b-row>
      <b-col lg="2" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Supporting Athletes'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Aquatics Partnerships
    </h2>
    <b-row>
      <b-col lg="2" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Aquatics Partnerships'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      People Development
    </h2>
    <b-row>
      <b-col lg="2" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'People Development'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Education Team
    </h2>
    <b-row>
      <b-col lg="2" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Education Team'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Finance Team
    </h2>
    <b-row>
      <b-col lg="2" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Finance Team'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Marketing Team
    </h2>
    <b-row>
      <b-col lg="2" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Marketing Team'" class="marginBtn-hall">
        <b-nav-item class='hof' :to=" '/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
    <h2 class="headerTitle">
      Services Team
    </h2>
    <b-row>
      <b-col lg="2" v-for="(person, index) in fame" :key="index" v-show="person.acf.category === 'Services Team'" class="marginBtn-hall">
        <b-nav-item class='hof' :to="'/about-us/meet-the-team/' + person.slug">
          <div>
            <div class="outerImage">
              <img :src="person.acf.image">
            </div>
          </div>
          <b-card
            :title="person.acf.title"
            :sub-title="person.acf.job_role"
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
          >
          </b-card>
        </b-nav-item>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "meettheteam",
  data() {
    return {
      fame: [],
      page: [],
    };
  },

  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.wpInfo("wp/v2/pages?slug=meet-the-team")
      this.page = first_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
  created() {
    apiCall
      .wpInfo("wp/v2/meet-the-team?per_page=50")
      .then(({ data }) => {
        this.fame = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
h6.card-subtitle.text-muted.mb-2 {
  text-align: center;
  color: #000!important;
  font-size: 11pt;
}
.card-title {
  font-size: 17px !important;
  color: black;
  font-weight: 600;
  text-align: center;
  margin-top: 70px;
}
.marginBtn-hall {
  margin-bottom: 100px;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  height: 160px;
}
.card {
  border: 0;
  background-color: #e0f5ff;
}
.outerImage {
  height: 130px;
  width: 130px;
  background: #ececec;
  border-radius: 100px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: -65px;
  z-index: 9;
  left: 0;
  margin: auto;
}
.trueRight > img.card-img-top {
  width: 100%;
  height: auto;
}

img.card-img-top {
  width: 159px;
  height: 159px;
}
h2.headerTitle {
  font-weight: bold;
  padding: 30px 0;
  color: #201546;
  margin-bottom: 100px;
  font-family: GT-Haptik-Medium;
  font-weight: 500;
}
::v-deep .card-title {
  font-size: 17px !important;
  color: black;
  font-weight: 600;
}
.card {
  border: 0;
}
.hof>a{
  padding: 0;  
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0;
  padding-top: 10px;
}
::v-deep .card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
  color: #009fe3!important;
}
::v-deep .card-subtitle:hover {
  margin-top: -0.375rem;
  margin-bottom: 0;
  color: #009fe3!important;
  text-decoration: underline;
}
@media only screen and (max-width: 768px) {
  img.card-img-top {
    width: 100%;
    height: 100%;
  }
}
</style>
