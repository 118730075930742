<template>
  <div style="position:relative;">
    <div v-if="this.video === undefined || this.video === false">
      <div :class="{'overlay':over === true}" v-if="$route.path === '/about-us/governance/districts'">
        <b-img class="splashdis" :class="{'op':over === false}" :src="bannerImage"></b-img>
      </div>
      <div :class="{'overlay':over === true}" v-else>
        <b-img class="splash" :class="{'op':over === false}" :src="bannerImage"></b-img>
      </div>
    </div>
    <div v-else-if="this.bannerImage === false && this.video.length > 0">
        <video type="video" aspect="4by3" muted allowfullscreen autoplay loop>
          <source :src="video" type="video/webm">
          <source :src="video" type="video/mp4">
        </video>
    </div>
    <template v-if="title === 'none'"></template>
    <template v-else>
      <h1 class="title" v-html="title"></h1>
    </template>
  </div>
</template>

<script>
export default {
  name: "splash",
  props: ["bannerImage", "title", "video"],
  computed:{
    over:function(over){
      if(this.title === 'none'){
        over = false
      } else{
        over = true
      }
      return over
    }
  },
};
</script>

<style scoped>
::v-deep video {
  width: 100%;
}
::v-deep .embed-responsive-4by3::before {
  padding-top: 57%;
  margin-top: -9px;
}
.overlay {
  background-color: #201546;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/HomePage_Overlay_1@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.splash {
  /* opacity: 0.46; */
  /* height: 603px; */
  height: 503px;
  /* height: auto; */
  object-fit: cover;
}
.splashdis {
  /* opacity: 0.46; */
  /* height: 603px; */
  /* height: 503px; */
  height: auto;
  object-fit: cover;
}
.op{
  opacity: 1!important;
}
.title {
  position: absolute;
  top: 277px;
  width: 100%;
  text-align: center;
  height: 75px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000058;
  font-weight: bold;
  font-size: 3.5rem;
}
@media only screen and (max-width: 500px) {
  .splash {
    /* opacity: 0.46; */
    height: 300px;
    object-fit: cover;
  }
  .title {
    top: 106px;
    font-size: 2.5rem;
  }
}
</style>
