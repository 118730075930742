<template>
  <div>
    <div id="content">
      <Splash title="none" :bannerImage="event[0].acf.image"/>
      <div class="ourContainer">
        <!-- <Route /> -->
        <div class="wp">
          <b-row>
            <b-col :class="{ 'col-lg-9 col-md-9': event[0].acf.right_sidebar === true }">
              <b-nav-item class="back" to="/events"
                > 
                <font-awesome-icon class="icon" :icon="['fa', 'chevron-left']"/>
                Back To Events</b-nav-item
              >
              <h1 class="eventsHead">{{event[0].title.rendered}}</h1>
              <p class="eventsSection" v-html="event[0].acf.dates"></p>
              <span class="wpContent" v-html="event[0].content.rendered"></span>


              <template v-if="event[0].acf.dropdown_area_show === true">
                <div style="margin-top:30px ">
                  <div>
                    <b-card
                      no-body
                      class="mb-1"
                      v-for="(drop, index) in event[0].acf.dropdown_area"
                      :key="index"
                    >
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                          block
                          v-b-toggle="'one' + index"
                          :id="index"
                          class="faqQ"
                          >
                          <span class="recordsTitle">
                            {{ drop.section_title }}
                          </span>
                          <span class="arrow" >
                            <span class="plusIcon">
                              <font-awesome-icon class="icon" :icon="['fa', 'plus']"/>
                            </span>
                          </span>
                        </b-button>
                      </b-card-header>
                      <b-collapse
                        :id="'one' + index"
                        :accordion="'one' + index"
                        role="tabpanel" 
                      >
                        <b-card-body class="profileCard">
                          <b-card-text  v-html="drop.section_content"></b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </div>
              </template>
            </b-col>
            <!-- Right Side Bar -->
            <template v-if="event[0].acf.right_sidebar === true">
              <b-col>
                <!-- Ads -->
                <template v-if="event[0].acf.ad_links.length">
                  <SideBarBlock
                    v-for="(link, index) in event[0].acf.ad_links"
                    :key="index"
                    type="ad"
                    :image="link.image"
                    :url="link.url"
                  />
                </template>
                <!-- End Ads -->

                <!-- Downloads -->
                <template v-if="$route.path === '/our-sports/swimming/records'">
                  <template v-if="event[1].acf.downloads.length">
                    <div class="downloadsLink">
                      <h4 class="sideTitle">
                        <template v-if="event[1].acf.downloads_header_text == true ">
                          <h4 class="sideTitle downTop">
                            {{event[1].acf.downloads_header_text}}
                            <hr class="hrLine">
                          </h4>
                        </template>
                        <template v-else>
                          <h4 class="sideTitle downTop">
                            Downloads
                            <hr class="hrLine">
                          </h4>
                        </template>
                      </h4>
                      <SideBarBlock
                        v-for="(link, index) in event[1].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>
                <template v-else-if="$route.path === '/our-sports/para-swimming/records'">
                  <template v-if="event[3].acf.downloads.length">
                    <div class="downloadsLink">
                      <template v-if="event[3].acf.downloads_header_text == true ">
                        <h4 class="sideTitle downTop">
                          {{event[3].acf.downloads_header_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle downTop">
                          Downloads
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in event[3].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>
                <template v-else-if="$route.path === '/our-sports/masters/records'">
                  <template v-if="event[2].acf.downloads.length">
                    <div class="downloadsLink">
                      <template v-if="event[2].acf.downloads_header_text == true ">
                        <h4 class="sideTitle downTop">
                          {{event[2].acf.downloads_header_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle downTop">
                          Downloads
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in event[2].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>
                <template v-else>
                  <template v-if="event[0].acf.downloads.length">
                    <div class="downloadsLink">
                      <template v-if="event[0].acf.downloads_header_text == true ">
                        <h4 class="sideTitle downTop">
                          {{event[0].acf.downloads_header_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle downTop"> Downloads 
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in event[0].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>                  

                <!--End Downloads -->

                <!-- URL Links -->
                <template v-if="event[0].acf.useful_links.length">
                  <div class="usefulLinks">
                    <template v-if="event[0].acf.usefull_links_text == true">
                      <h4 class="sideTitle top">
                        {{event[0].acf.usefull_links_text}}
                        <hr class="hrLine">
                      </h4>
                    </template>
                    <template v-else>
                      <h4 class="sideTitle top">
                        Useful Links
                        <hr class="hrLine">
                      </h4>
                    </template>
                    <SideBarBlock
                      v-for="(link, index) in event[0].acf.useful_links"
                      :key="index"
                      type="link"
                      :title="link.title"
                      :url="link.url"
                    />
                  </div>
                </template>                  

                <!-- End URL Links -->
              </b-col>
            </template>
            <!-- End Right Side Bar -->
          </b-row>
        </div>
      </div>
    </div>   
    <Footer /> 
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
// import Route from '@/components/route.vue'
import Splash from '@/components/splash.vue'
import SideBarBlock from "@/components/sidebarBlock.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "eventPage",
  components:{
    // Route,
    Splash,
    SideBarBlock,
    Footer
  },
  props: ["dis", "slug"],
  data() {
    return {
      event: [],
      parentId: "",
      page: [],
      eventTitle: '',
      description: ''
    };
  },
  metaInfo() {
    return {
      title: this.eventTitle,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.wpInfo("wp/v2/pages?slug=" + this.dis);
      this.parentId = first_call.data[0].id;
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall.wpInfo(
        "wp/v2/pages?slug=events" + "&parent=" + this.parentId
      );
      this.page = second_call.data;
      this.loading = false;
      let third_call = await apiCall.wpInfo("wp/v2/events?slug=" + this.slug);
      this.event = third_call.data;
      this.eventTitle = third_call.data[0].title.rendered;
      console.log("this.eventTitle")
      console.log(this.eventTitle)
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
};
</script>

<style scoped>
.collapsed:after {
  content: 'Open';
  float: right;
  padding-right: 15px;
}
.not-collapsed:after {
  content: 'Close';
  float: right;
  padding-right: 15px;
}
.faqQ {
  background-color: transparent;
  border: 0;
  text-align: left;
  color: #1f1545;
  background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/openbg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  font-weight: 600;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 13pt;
}
.faqQ:hover {
  background-color: transparent;
  border: 0;
  text-align: left;
  color: #1f1545;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/openbgHover.jpg);
  border: none!important;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  font-weight: 600;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 13pt;
}
.DropBut {
  background-color: white;
  color: #009fe3;
  font-style: italic;
  border: 0;
  text-align: left;
}
.accordion > .card.drop {
  border-top: 1px solid!important;
  border:none;
  border-radius: 0px;
}
.accordion > .card.drop > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
  border: 0px;
  box-shadow: 0px;
  padding: 0px!important;
}
span.arrow {
  float: right;
  display: grid;
  text-align: center;
}
li.nav-item.linkText {
  line-height: 18px;
}
hr.hrLine {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  border-top: 1px solid #1f1545;
}
h4.sideTitle.downTop {
  margin-top: -10px;
}
.downloadsLink {
  background-color: #cbebf9;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  border-radius: 5px;
  padding-right: 10px;
  margin-top: 10px;
}
.usefulLinks {
  background-color: #cbebf9;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
  margin-top: 10px;
  border-radius: 5px;
  padding-right: 10px;
}
.back {
  text-align: left;
  text-transform: capitalize;
}
.back > a {
  color: #201546!important;
  padding-left: 0px!important;
  margin-bottom: 30px;
}
.back:hover{
  font-weight: bold;
}
p.eventsSection {
  color: #1f1545;
  margin-bottom: 40px;
}
h1.eventsHead {
  color: #1f1545;
  padding-bottom: 20px;
  font-family: GT-Haptik-Medium;
  font-size: 2.5rem;
}
::v-deep .sideTitle {
  color: #201546;
  font-size: 17px;
  font-weight: 100;
  padding-bottom: 20px;
  padding: 15px 0px 20px 0;
  padding-top: 20px;
  font-family: GT-Haptik-Medium;
  margin-bottom: -15px;
}
#content {
  padding-left: 20%;
}
.overlay {
  background-color: #201546;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/HomePage_Overlay_1@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.splash {
  opacity: 0.46;
  height: 603px;
  object-fit: cover;
}
.title {
  position: absolute;
  top: 277px;
  width: 100%;
  text-align: center;
  height: 75px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000058;
  font-weight: bold;
  font-size: 3.5rem;
}
.route {
  display: inline-flex;
}
.route > li > a {
  padding: 0.5rem 0;
  text-transform: uppercase;
  font-weight: bold;
}
.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.wp {
  /* padding-top: 50px; */
}
::v-deep .wpContent > h2 {
  padding-bottom: 30px;
  color: #201546;
  font-size: 2.5rem;
  font-weight: 500;
}
::v-deep .wpContent > p {
  color: #201546;
  font-size: 16px;
}
::v-deep .wpContent > figure {
  padding-top: 20px;
}
.nav-item > a {
  color: #009fe3;
}
.hide0{
  display: none;
}
@media only screen and (max-width: 990px) {
  #content {
    padding-left: 0;
  }
  ::v-deep .wp {
    padding-top: 10px!important;
  }
  .ourContainer {
    max-width: none;
    margin: 0px auto;
    padding: 0 0px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
