<template>
<div>
  <div id="content">
        <Splash
          :bannerImage="page[0].acf.banner_image"
          :video="page[0].acf.video"
        />
      <div :class="isMobile(true)" class="overImagecontent">
        <div class="ourContainer" v-if="$route.path === '/learn-to-swim'">
            <template v-if="page[0].slug !== 'records' && page[0].slug !== 'volunteer-stories'">
                <Tileshow />
            </template>
        </div>
        <template v-if="page[0].content.rendered.length > 0">
            <div class="rippleImage"></div>
            <div class="outerContainerImage">
                <div class="wp ourContainer">
                    <b-row>
                        <b-col
                            cols="12"
                            :class="{ 'col-lg-9 col-md-9': page[0].acf.right_sidebar === true }"
                        >
                            <!-- This is Wordpress rendered content for the page -->
                            <template v-if="$route.path.includes('important-documents')">
                            <b-row>
                                <b-col v-for="(importantDocs, index) in page[0].acf.text_box" :key="index" cols="12" lg="4" md="4">
                                <b-nav-item :to='importantDocs.url' class="backgroundNavItem">
                                    <div class="outerImportantDocsText">
                                    <div class="importantDocsText">
                                        {{importantDocs.text}}
                                    </div>
                                    <font-awesome-icon class="icon" :icon="['fa', 'arrow-right']"/>
                                    </div>
                                </b-nav-item>
                                </b-col>
                            </b-row>
                            </template>
                            <template>
                            <span class="hidemob wpContent" v-html="page[0].content.rendered"></span>
                            <div class="hidedesk" v-if="page[0].content.rendered.length > 0">
                                <span v-bind:class="[isActive ? 'noexpand' : 'expand']" class="wpContent" v-html="page[0].content.rendered.substring(0,1000)"></span>
                                <div>
                                <b-button @click="Colorcss()" class="readMoreBtn" v-b-toggle.collapse-1 variant="primary">Read More</b-button>
                                <b-collapse id="collapse-1" class="mt-2 wpcontentHeight readMoreContent">
                                    <span class="wpContent" v-html="page[0].content.rendered.substring(1000,100000)"></span>
                                </b-collapse>
                                <b-button @click="Colorcss()" class="readLessBtn" v-b-toggle.collapse-1 variant="primary">Read Less</b-button>
                                </div>
                            </div>
                            </template>
                            <!-- End is Wordpress rendered content for the page -->
                        </b-col>
                        <template v-if="page[0].acf.right_sidebar === true">
                            <b-col>
                            <!-- Ads -->
                            <template v-if="page[0].acf.ad_links.length">
                                <SideBarBlock
                                v-for="(link, index) in page[0].acf.ad_links"
                                :key="index"
                                type="ad"
                                :image="link.image"
                                :url="link.url"
                                />
                            </template>
                            <!-- End Ads -->

                            <!-- Downloads -->
                            <template v-if="$route.path === '/our-sports/swimming/records'">
                                <template v-if="page[1].acf.downloads.length">
                                <div class="downloadsLink">
                                    <h4 class="sideTitle">
                                    <template v-if="page[1].acf.downloads_header_text == true ">
                                        <h4 class="sideTitle downTop">
                                        {{page[1].acf.downloads_header_text}}
                                        <hr class="hrLine">
                                        </h4>
                                    </template>
                                    <template v-else>
                                        <h4 class="sideTitle downTop">
                                        Downloads
                                        <hr class="hrLine">
                                        </h4>
                                    </template>
                                    </h4>
                                    <SideBarBlock
                                    v-for="(link, index) in page[1].acf.downloads"
                                    :key="index"
                                    type="pdf"
                                    :title="link.title"
                                    :url="link.download_url"
                                    />
                                </div>
                                </template>
                            </template>
                            <template v-else-if="$route.path === '/our-sports/para-swimming/records'">
                                <template v-if="page[3].acf.downloads.length">
                                <div class="downloadsLink">
                                    <template v-if="page[3].acf.downloads_header_text == true ">
                                    <h4 class="sideTitle downTop">
                                        {{page[3].acf.downloads_header_text}}
                                        <hr class="hrLine">
                                    </h4>
                                    </template>
                                    <template v-else>
                                    <h4 class="sideTitle downTop">
                                        Downloads
                                        <hr class="hrLine">
                                    </h4>
                                    </template>
                                    <SideBarBlock
                                    v-for="(link, index) in page[3].acf.downloads"
                                    :key="index"
                                    type="pdf"
                                    :title="link.title"
                                    :url="link.download_url"
                                    />
                                </div>
                                </template>
                            </template>
                            <template v-else-if="$route.path === '/our-sports/masters/records'">
                                <template v-if="page[2].acf.downloads.length">
                                <div class="downloadsLink">
                                    <template v-if="page[2].acf.downloads_header_text == true ">
                                    <h4 class="sideTitle downTop">
                                        {{page[2].acf.downloads_header_text}}
                                        <hr class="hrLine">
                                    </h4>
                                    </template>
                                    <template v-else>
                                    <h4 class="sideTitle downTop">
                                        Downloads
                                        <hr class="hrLine">
                                    </h4>
                                    </template>
                                    <SideBarBlock
                                    v-for="(link, index) in page[2].acf.downloads"
                                    :key="index"
                                    type="pdf"
                                    :title="link.title"
                                    :url="link.download_url"
                                    />
                                </div>
                                </template>
                            </template>
                            <template v-else>
                                <template v-if="page[0].acf.downloads.length">
                                <div class="downloadsLink">
                                    <template v-if="page[0].acf.downloads_header_text == true ">
                                    <h4 class="sideTitle downTop">
                                        {{page[0].acf.downloads_header_text}}
                                        <hr class="hrLine">
                                    </h4>
                                    </template>
                                    <template v-else>
                                    <h4 class="sideTitle downTop"> Downloads 
                                        <hr class="hrLine">
                                    </h4>
                                    </template>
                                    <SideBarBlock
                                    v-for="(link, index) in page[0].acf.downloads"
                                    :key="index"
                                    type="pdf"
                                    :title="link.title"
                                    :url="link.download_url"
                                    />
                                </div>
                                </template>
                            </template>                  

                            <!--End Downloads -->

                            <!-- URL Links -->
                            <template v-if="page[0].acf.useful_links.length">
                                <div class="usefulLinks">
                                <template v-if="page[0].acf.usefull_links_text == true">
                                    <h4 class="sideTitle top">
                                    {{page[0].acf.usefull_links_text}}
                                    <hr class="hrLine">
                                    </h4>
                                </template>
                                <template v-else>
                                    <h4 class="sideTitle top">
                                    Useful Links
                                    <hr class="hrLine">
                                    </h4>
                                </template>
                                <SideBarBlock
                                    v-for="(link, index) in page[0].acf.useful_links"
                                    :key="index"
                                    type="link"
                                    :title="link.title"
                                    :url="link.url"
                                />
                                </div>
                            </template>                  

                            <!-- End URL Links -->
                            </b-col>
                        </template>
                    </b-row>
                </div>
            </div>
        </template>

        <!-- individual Games Block  -->
        <div class="wp ourContainer">
            <h1 class="TitleGame" v-html="pagegame[0].acf.game_name"></h1>
            <b-row class="GameBlock">
                <b-col v-for="(game, index) in pagegame[0].acf.game_description" :key="index" class="">
                    <b-card
                        :title="game.title"
                        tag="article"
                        style="max-width: 20rem;"
                        class="mb-2 backgroundPool"
                    >
                        <b-card-text v-html="game.description">
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
        </div>
        <!-- End individual Games Block  -->
        
        <div class="wp ourContainer" v-if="page[0].acf.form_activate === true || page[0].acf.faq === true || page[0].acf.dropdown_area_show === true || page[0].acf.health_section === true">
            <!-- Form -->
            <template v-if="page[0].acf.form_activate === true">
                <Form :page="page[0]" />
            </template>
            <!-- End of Form -->

            <!-- FAQs -->
            <template v-if="page[0].acf.faq === true">
            <div class="accordion" role="tablist">
                <b-card
                no-body
                class="mb-1"
                v-for="(faq, index) in page[0].acf.faq_list"
                :key="index"
                >
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button
                    block
                    v-b-toggle="'faq' + index"
                    :id="index"
                    class="faqQ"
                    >
                    {{ faq.question }}
                        <span class="arrow" >
                        <span class="plusIcon">
                            <font-awesome-icon class="icon" :icon="['fa', 'plus']"/>
                        </span>
                        </span>
                    </b-button>
                </b-card-header>
                <b-collapse
                    :id="'faq' + index"
                    :accordion="'faq' + index"
                    role="tabpanel"
                >
                    <b-card-body>
                    <b-card-text>{{ faq.answer }}</b-card-text>
                    <b-card-text>{{ text }}</b-card-text>
                    </b-card-body>
                </b-collapse>
                </b-card>
            </div>
            </template>
            <!-- End FAQs -->

            <!-- dropdown sec -->
            <template v-if="page[0].acf.dropdown_area_show === true">
            <div style="margin-top:30px ">
                <div>
                <b-card
                    no-body
                    class="mb-1"
                    v-for="(drop, index) in page[0].acf.dropdown_area"
                    :key="index"
                >
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button
                        block
                        v-b-toggle="'one' + index"
                        :id="index"
                        class="faqQ"
                        >
                        <span class="recordsTitle">
                        {{ drop.section_title }}
                        </span>
                        <span class="arrow" >
                        <span class="plusIcon">
                            <font-awesome-icon class="icon" :icon="['fa', 'plus']"/>
                        </span>
                        </span>
                    </b-button>
                    </b-card-header>
                    <b-collapse
                    :id="'one' + index"
                    :accordion="'one' + index"
                    role="tabpanel"
                    >
                    <b-card-body class="profileCard">
                        <b-card-text  v-html="drop.section_content"></b-card-text>
                    </b-card-body>
                    </b-collapse>
                </b-card>
                </div>
            </div>
            </template>
            <!-- End of dropdown -->

            <!-- Health Banner -->
            <template v-if="page[0].acf.health_section === true">
            <Benefits :page="page[0]" />
            </template>
            <!-- End Health Banner -->

        </div>
      </div>
    <!-- Sign Up Banner -->
  </div>  
    <Footer />
</div>

</template>

<script>
import apiCall from "@/services/api.js";
import Footer from "@/components/footer.vue"
import SideBarBlock from "@/components/sidebarBlock.vue";
import Splash from "@/components/splash.vue";
import Form from "@/components/Form.vue";
import Tileshow from "@/components/tileshow.vue";

export default {
  name: "Games",
  props: ["slug"],
  components: {
    Footer,
    Form,
    SideBarBlock,
    Splash,
    Tileshow
  },
  data() {
    return {
      page: [],
      pagegame:[],
      isActive: true,
    };
  },
  methods: {
    itemLink(item) {
      var low = item.toLowerCase();
      var rep = low.replace(/ /g, "-");
      return rep;
    },
    Colorcss: function(){
      this.isActive = !this.isActive;
    },
    isMobile() {
      if( screen.width <= 760 ) {
          this.container = 'container'
          return true;
      }
      else {
          this.container = 'nocontainer'
          return false;
      }
    }
  },
  async mounted() {
    try {
      let first_call = await apiCall.wpInfo(
        "wp/v2/pages?slug=" + this.itemLink(this.$route.name)
      );
      this.page = first_call.data;
      // First call, await for call to return
      let second_call = await apiCall.wpInfo(("wp/v2/games?slug=" + this.slug)
      );
      this.pagegame = second_call.data;
      this.pageID = second_call.data[0].id;
      this.loading = false;
      let third_call = await apiCall.wpInfo(
       "wp/v2/games?_embed&per_page=100"
      )
      this.pageSec = third_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
};
</script>

<style scoped>
article.card.mb-2 {
    border: 0px solid rgba(0, 0, 0, 0.125);
}
p.card-text {
  color: #1f1546;
  font-weight: 700;
}
h1.TitleGame {
  color: #1f1545;
  padding-bottom: 50px;
  font-family: GT-Haptik-Medium;
  font-size: 2.5rem;
  text-align: center;
}
li.nav-item.waterLink > a {
  padding: 0px;
}
.waterSaftyBlock {
  margin-bottom: 30px;
}
.overImagecontent {
 position: relative;
 top: -25px;
}
.waterSafetyImage {
 margin-bottom: 30px;
}
.story {
  background-color: #fff;
}
.backgroundPool {
  background-color: #e0f4fc;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: -25px;
}
.wpcontentH1 {
  color: #1f1545;
  padding-bottom: 20px;
  font-family: GT-Haptik-Medium;
  font-size: 1.5rem;
  margin-top: 30px;
}
.rippleImageReverse {
  background-repeat: no-repeat;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Reversed-Ripple.png);
  top: 0px;
  z-index: 9999;
  display: block;
  height: 28px;
  position: relative;
}
.rippleImage {
  background-repeat: no-repeat;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/blue-top.png);
  top: -23px;
  z-index: 9999;
  display: block;
  height: 28px;
}
.orangerippleImage {
  background-repeat: no-repeat;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Orange-top.png);
  top: -25px;
  z-index: 9999;
  display: block;
  height: 28px;
  position: relative;
}
.outerContainerImageOrange {
  background-color: #fd6349;
  margin-top: -68px;
}
.outerContainerImage {
  background-color: #48b8e7;
  margin-top: -42px;
  padding-bottom: 10px;
}
li.nav-item.linkText {
  line-height: 18px;
}
hr.hrLine {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  border-top: 1px solid #1f1545;
}
h4.sideTitle.downTop {
  /* margin-top: -10px; */
}
.downloadsLink {
  background-color: #cbebf9;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  border-radius: 5px;
  padding-right: 10px;
  margin-top: 10px;
}
.usefulLinks {
  background-color: #cbebf9;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
  margin-top: 10px;
  border-radius: 5px;
  padding-right: 10px;
}
.storyMargin {
  margin-top: 60px;
}
p.SportsSection {
  color: #1f1545;
}
::v-deep .story > iframe {
  height: 260px!important;
}
.storyouterName {
  height: 66px;
  border-radius: 0px 33px 0px 0px;
  font-size: 16px;
  font-weight: bold;
  margin-top: -66px;
  position: absolute;
  padding: 20px;
  text-transform: capitalize;
  padding-right: 75px;
  transition: padding-right 0.5s;
  background-color: #fd6349;
  cursor: pointer;
}
::v-deep span.storyName {
  color: #fff;
}
.storyouterName:hover {
  padding-right: 140px;
}
.storyouterName:hover:after {
  opacity: 1;
  right: 40px;
  position: absolute;
}
.storyouterName:after {
  content: '»';
  opacity: 0;
}
.accordion > .card > .card-header {
  padding: 0px!important;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  padding: 0px!important;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}
.card.mb-1 {
  /* padding: 0px; */
  border: 0;
  margin-bottom: 10px!important;
}
.outerImportantDocsText {
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100px;
}
.importantDocsText {
  width: 150px;
  text-align: center;
  margin: auto;
}
li.nav-item.backgroundNavItem {
  background: #eff8fc;
  margin-bottom: 50px;
}
li.nav-item.backgroundNavItem:hover {
  background: #a8d6f0;
}
li.nav-item.backgroundNavItem > a{
  color: #000;
  font-weight: 600;
  text-align: center;
}
h1.mainPagesHead {
  color: #1f1545;
  padding-bottom: 20px;
  font-family: GT-Haptik-Medium;
  font-size: 2.5rem;
}
.Tiletitle {
  height: 66px;
  border-radius: 0 33px 33px 0;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  margin-top: -33px;
  position: absolute;
  padding: 20px;
  text-transform: uppercase;
  padding-right: 75px;
  transition: padding-right .5s;
  background-color: #fd6349;
}
.disCol > .nav-item > .nav-link {
  padding: 0;
}
.disCol {
  padding: 0 5px 50px 5px;
}
::v-deep .hidedesk > span.noexpand > p:last-child {
  opacity: .3;
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}
::v-deep .hidedesk > span.expand > p:last-child {
  opacity: 1;
}
.readMoreContent > .wpContent {
  color: #201546;
}
button.btn.readMoreBtn.btn-primary.not-collapsed {
  display: none;
}
button.btn.readLessBtn.btn-primary.collapsed {
  display: none;
}
.wpcontentHeight {
  /* margin-top: 30px!important; */
  display: block;
}
.readMoreBtn {
  background-color: #201646!important;
  margin-top: 25px;
  margin-bottom: 25px;
  border-color: white;
}
.readLessBtn {
  background-color: #201646!important;
  margin-top: 25px;
  margin-bottom: 25px;
  border-color: white;
}
::v-dee .html5-endscreen.ytp-player-content.videowall-endscreen.ytp-endscreen-paginate.ytp-show-tiles {
  display: none!important;
}
::v-deep iframe {
  width: 100%;
  height: 300px;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #1f1545;
  background-color: #eff8fc;
  border-color: #545b62;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}
button.print:hover {
  background-color: #009fe3;
  color: #fff;
  border: #201546;
  padding: 10px;
  border-radius: 2px;
  margin-left: 8px;
}
button.print {
  background-color: #201546;
  color: #fff;
  border: #201546;
  padding: 10px;
  border-radius: 2px;
  margin-left: 8px;
  display: none;
}
iframe.iframeBoarder {
  width: 100%;
  height: 1200px;
}
#content {
  padding-left: 20%;
}
.overlay {
  background-color: #201546;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/HomePage_Overlay_1@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.splash {
  opacity: 0.46;
  height: 603px;
  object-fit: cover;
}
.title {
  position: absolute;
  top: 277px;
  width: 100%;
  text-align: center;
  height: 75px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000058;
  font-weight: bold;
  font-size: 3.5rem;
}
.route {
  display: inline-flex;
}
.route > li > a {
  padding: 0.5rem 0;
  text-transform: uppercase;
  font-weight: bold;
}
.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.wp {
  padding-top: 50px;
}
.wp > .row {
  margin-left: 0px;
  margin-right: 0px;
}
h2 {
  padding: 50px 0 0 0;
  color: #201546;
  margin-bottom: -10px;
}
h4 {
  padding: 50px 0 0 0;
  color: #201546;
  /* margin-bottom: -10px; */
}
h4.card-title {
  padding-top: 15px;
  color: #fd6349!important;
  font-weight: 600;
  padding-bottom: 15px;
}
::v-deep .wpContent > h1 > strong{
  font-weight: 500;
}
::v-deep .wpContent > h1 {
  color: #1f1545;
  padding-bottom: 20px;
  font-family: GT-Haptik-Medium;
  font-size: 2.5rem;
}
::v-deep .wpContent > div > div > h1 {
  color: #1f1545;
  padding-bottom: 20px;
  font-family: GT-Haptik-Medium;
  font-size: 2.5rem;
}
::v-deep .wpContent > div.row {
  padding-top: 50px;
}
::v-deep .wpContent > h2 {
  /* padding: 30px 0; */
  padding: 21px 0;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Lessfussy.jpg);
  /* background-position: right; */
  background-position: initial;
  padding-left: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-size: cover;
  background-position: right;
}
::v-deep .wpContent > h2 > strong{
  font-weight: 500!important;
}
::v-deep .wpContent > p {
  color: #1a1041;
  /* color: #707070; */
  font-size: 16px;
}
::v-deep .wpContent > figure {
  padding-top: 20px;
}
::v-deep img {
  object-fit: contain;
}
.readMoreBtn.collapsed:after {
  content: ''!important;
  float: none!important;
  padding-right: 0px!important;
}
.readLessBtn.not-collapsed:after {
  content: ''!important;
  float: none!important;
  padding-right: 0px!important;
}
.collapsed:after {
  content: 'Open';
  float: right;
  padding-right: 15px;
}
.not-collapsed:after {
  content: 'Close';
  float: right;
  padding-right: 15px;
}
.faqQ {
  background-color: transparent;
  border: 0;
  text-align: left;
  color: #1f1545;
  background-image: url('https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/openbg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  font-weight: 600;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 13pt;
}
.faqQ:hover {
  background-color: transparent;
  border: 0;
  text-align: left;
  color: #1f1545;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/openbgHover.jpg);
  border: none!important;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  font-weight: 600;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 13pt;
}
.DropBut {
  background-color: white;
  color: #009fe3;
  font-style: italic;
  border: 0;
  text-align: left;
}
.accordion > .card.drop {
  border-top: 1px solid!important;
  border:none;
  border-radius: 0px;
}
.accordion > .card.drop > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
  border: 0px;
  box-shadow: 0px;
  padding: 0px!important;
}
span.arrow {
  float: right;
  display: grid;
  text-align: center;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #1f1545;
  background-color: #eff8fc;
  border-color: #545b62;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}
/* .arrow {
  border: solid #009fe3;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  right: 10px;
  top: 15px;
} */

.nav-item > a {
  color: #009fe3;
}
::v-deep .wpContent > ul > li {
  list-style-type: disc;
  color: #201546;
}
.hide0 {
  display: none;
}
::v-deep .sideTitle {
  color: #201546;
  font-size: 17px;
  font-weight: 100;
  padding-bottom: 20px;
  padding: 15px 0px 20px 0;
  padding-top: 10px;
  font-family: GT-Haptik-Medium;
  margin-bottom: -15px;
}
.route > li:first-child > a {
  text-decoration: underline;
}
.route > li:nth-child(2) > a {
  text-decoration: underline;
}
::v-deep .wpContent > p > a {
  color: #fff;
}
::v-deep table {
  width: 100%;
}
::v-deep th {
  background: #009fe3 0% 0% no-repeat padding-box;
  color: #fff;
  padding: 20px 16px 19px 21px;
}
::v-deep td {
  border: 4px solid #fff;
  padding: 17px 18px 17px 21px;
  font-size: 14px;
  color: #201546;
  width: 7%;
}
::v-deep .wpContent > ul > li > a {
  color: #009fe3;
}
::v-deep tr {
  background-color: #f7f7f7;
}
.top {
  /* padding-top: 50px; */
  padding-top: 0px;
  margin-top: 15px;
}
.bottom {
  padding-bottom: 30px;
}
::v-deep a {
  color: #009fe3;
  text-decoration: none;
  background-color: transparent;
}
@media only screen and (min-width: 991px) {
  ::v-deep .hidedesk {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  h1.TitleGame[data-v-18bb9097] {
    color: #1f1545;
    padding-bottom: 50px;
    font-family: GT-Haptik-Medium;
    font-size: 2.5rem;
    text-align: center;
    margin-top: 1em;
  }
  .backgroundPool[data-v-18bb9097] {
    background-color: #e0f4fc;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: -25px;
    max-width: 100%!important;
    margin-bottom: 30px!important;
  }
  .GameBlock {
    display: block;
  }
  ::v-deep .wpContent > h2 {
    padding: 21px 0;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Lessfussy.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center!important;
    padding-left: 30px;
    padding-right: 30px;
  }
  ::v-deep .hidemob {
    display: none;
  }
  ::v-deep img {
    -o-object-fit: contain;
    object-fit: contain;
    height: auto;
  }
  ::v-deep img.wp-image-8616 {
    object-fit: contain!important;
    height: auto;
  }
  #content {
    padding-left: 0;
  }
  ::v-deep .wp {
    padding-top: 10px !important;
  }
  ::v-deep iframe {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  
  ::v-deep .wpContent > div.row {
    padding-top: 50px;
    display: block;
  }
  .outerContainerImage {
    background-color: #48b8e7;
    margin-top: -2px;
  }
  .usefulLinks {
    margin-bottom: 30px;
  }
  ::v-deep .wpContent > figure {
    padding-top: 20px;
    overflow: scroll;
  }
  .ourContainer {
    max-width: none;
    margin: 0px auto;
    padding: 0 0px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
</style>