<template>
  <div class="route">
    <b-nav-item
      :to="'/' + routeLink(index)"
      v-for="(r, index) in route"
      :key="index"
      ><span class='noLine' :class="'hide' + index">/</span
      ><span class="name">{{ routeTitle(r) }}</span></b-nav-item
    >
  </div>
</template>

<script>
export default {
  name: "route",
  computed: {
    route: function() {
      var r = this.$route.fullPath.split("/");
      if (r[0] === "") {
        r[0] = "home";
      }
      return r;
    },
  },
  methods: {
    routeTitle(item) {
      var low = item;
      var rep = low.split("-").join(" ");
      return rep;
    },
    routeLink(index) {
      var item = this.route.slice(0, index + 1).join("/");
      var rebuild = item;
      return rebuild;
    },
  },
};
</script>

<style scoped>
.route {
  display: inline-flex;
}
.route > li > a {
  padding: 0.5rem 0;
  text-transform: uppercase;
  font-weight: bold;
  color: #009fe3;
  
}
.route > li:last-child > a > .name {
  text-decoration: none;
}
.route > li:first-child > a > span {
  padding-left: 0;
}
.hide0 {
  display: none;
}
.noLine{
  text-decoration: none!important;
}
.name {
  padding: 0 5px;
  text-decoration: underline;
}
@media only screen and (max-width: 768px) {
  .route > li > a {
    padding: 0.5rem 0;
    text-transform: uppercase;
    font-weight: bold;
    color: #009fe3;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
@media only screen and (max-width: 480px) {
  .name{
    font-size: 13px;
  }
  .route {
    display: inline-flex;
    flex-wrap: wrap;
  }
}

</style>
