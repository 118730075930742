<template>
  <div>
    <div id="content">
      <div class="ourContainer">
        <h2 class="title">
          Site Map
        </h2>
        <b-nav-item class='sm' to="/our-sports">{{ menu[1].title }}</b-nav-item>
        <template>
          <b-nav-item class="sub sm" to="/our-sports/swimming">Swimming</b-nav-item>
          <b-nav-item class="sub sm" to="/our-sports/para-swimming"
            >Para Swimming</b-nav-item
          >
          <b-nav-item class="sub sm" to="/our-sports/masters">Masters</b-nav-item>
          <b-nav-item class="sub sm" to="/our-sports/open-water"
            >Open Water</b-nav-item
          >
          <b-nav-item class="sub sm" to="/our-sports/diving">Diving</b-nav-item>
          <b-nav-item class="sub sm" to="/our-sports/artistic-swimming"
            >Artistic Swimming</b-nav-item
          >
          <b-nav-item class="sub sm" to="/our-sports/water-polo"
            >Water Polo</b-nav-item
          >
        </template>
        <template v-for="(item, index) in menu.slice(2)">
          <MenuLink :item="item" :key="index" :siteMap="true"/>
        </template>
      </div>
    </div>    
    <Footer />
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
import MenuLink from "@/components/menuLink.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "sitemap",
  components: { MenuLink, Footer },
  data() {
    return {
      menu: [],
    };
  },
  mounted() {
    apiCall
      .wpInfo("wp/v2/menu")
      .then(({ data }) => {
        this.menu = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
#content {
  padding-left: 20%;
}

.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.title {
  padding: 30px 0;
  color: #201546;
}
.sm>a{
  color: #009fe3 !important;
}
@media only screen and (max-width: 990px) {
  #content {
    padding-left: 0;
  }
}
</style>
