<template>
  <div>
    <div id="content">
      <Splash
        :bannerImage="page[0].acf.banner_image"
      />
      <div class="ourContainer">
        <!-- <Route />  -->
        <div>

          <b-row style="margin-top:30px">
            <b-col>
              <span class="wpContent" v-html="page[0].content.rendered"></span>
            </b-col>
          </b-row>
          <template>
            <b-row class="eventsCalandarBlock">
              <a class="linkEvent" href="/events/events-calendar/learning-and-development">
                <b-col>
                  <img class="imageSizeCalandar" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/01/i0x0753.jpg">
                  <div class="divBox ">
                    <span class="eventsTitle">Learning and Dev...</span>
                  </div>
                </b-col>
              </a>
              <a class="linkEvent" href="/events/events-calendar/networking-events">
                <b-col>
                  <img class="imageSizeCalandar" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/01/snags-65-.jpg">
                  <div class="divBox">
                    <span class="eventsTitle">Networking Events</span>
                  </div>
                </b-col>
              </a>
              <a class="linkEvent" href="/events/events-calendar/qualifications">
                <b-col>
                  <img class="imageSizeCalandar" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/01/3-qualificiations-south-lanarkshire-1.jpg">
                  <div class="divBox">
                    <span class="eventsTitle">Qualifications</span>
                  </div>
                </b-col>
              </a>
            </b-row>
          </template>

          <!-- <b-row v-if="filterEvents !== ''">
            <b-col>
              <template v-if="$route.name === 'major-events'">
                <h2>
                  Major Events
                </h2>
              </template>
              <template v-else-if="$route.name === 'events'">
                <h2>
                  All Events
                </h2>
              </template>
              <template v-else>
                <h2 style="text-transform: capitalize;">{{ routeTitle(Cat) }} Events</h2>
              </template>
            </b-col>
          </b-row> -->
          <UpComing :discipline="Cat" total="all" />
        </div> 
        <template v-if="$route.name === 'major-events'">
          <div class="nat">
            <b-row>
              <b-col>
                <h2>
                  National Events
                </h2>
              </b-col>
              <b-col>
                <b-input-group class="mt-3">
                  <b-form-select
                    v-model="pick"
                    placeholder="Search"
                    :options="options"
                  ></b-form-select>
                </b-input-group>
              </b-col>
            </b-row>
            <UpComing discipline="national-events" :pick="pick" total="all" />
          </div>
        </template>
      </div>
    </div>   
    <Footer /> 
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
import UpComing from "@/components/courseupComing.vue";
// import Route from "@/components/route.vue";
import Splash from "@/components/splash.vue";
import Footer from '@/components/footer.vue';
export default {
  name: "course events",
  components: { UpComing, Splash, Footer },
  metaInfo: {
    title: 'Course Events'
  },
  data() {
    return {
      page: [],
      parentId: "",
      newsPage: false,
      pick: "",
      options: [
        { value: "", text: "All" },
        { value: "swimming", text: "Swimming" },
        { value: "para-swimming", text: "Para Swimming" },
        { value: "diving", text: "Diving" },
        { value: "open-water", text: "Open Water" },
        { value: "water-polo", text: "Water Polo" },
        { value: "masters", text: "Masters" },
        { value: "artistic-swimming", text: "Artistic Swimming" },
      ],
    };
  },
  computed: {
    Cat: function(cat, rn) {
        var sp = this.$route.name.split("-");
        if (this.$route.name === "major-events") {
          cat = this.$route.name;
        } else {
          if (sp.length > 2) {
            rn = [sp[0] + "-" + sp[1]];
          } else {
            rn = sp;
          }
          if (rn[0] === "news") {
            cat = "none";
          } else {
            cat = rn[0];
          }
      }
      return cat;
    },
    route: function() {
      var r = this.$route.fullPath.split("/");
      if (r[0] === "") {
        r[0] = "home";
      }
      return r;
    },
  },
  methods: {
    routeTitle(item) {
      var low = item;
      var rep = low.replace(/-/g, " ");
      return rep;
    },
    async Dis() {
      var rn = this.Cat;
      if (rn === "none" || rn === "events" || rn === "major-events") {
        apiCall
          .wpInfo("wp/v2/pages?slug=" + rn)
          .then(({ data }) => {
            this.page = data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            throw error;
          });
      } else {
        try {
          // First call, await for call to return
        //   let first_call = await apiCall.wpInfo("wp/v2/pages?slug=" + rn);
        //   this.parentId = first_call.data[0].id;
        //   this.loading = false;
          // Second call, await for call to return
          let second_call = await apiCall.wpInfo(
            "wp/v2/pages?slug=course-calendar" + "&parent=" + this.parentId
          );
          this.page = second_call.data;
          this.loading = false;
        } catch (error) {
          console.log("error in await calls");
          console.log(error);
          throw error;
        }
      }
    },
  },
  mounted() {
    this.Dis();
  },
};
</script>

<style scoped>
.divBox:hover:after {
  opacity: 1;
  right: 40px;
  position: absolute;
}
.divBox:hover {
  padding-right: 80px;
}
.divBox:after {
  content: '»';
  opacity: 0;  
}
.divBox {
  background-color: #201546;
  height: 66px;
  border-radius: 0 33px 0 0;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  margin-top: -66px;
  position: absolute;
  padding: 20px;
  text-transform: capitalize;
  padding-right: 30px;
  transition: padding-right .5s;
}
#content {
  padding-left: 20%;
}

.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.nav-item > a {
  color: #009fe3;
}
.hide0 {
  display: none;
}
.nat {
  padding-top: 100px;
}
h2 {
  padding: 30px 0;
  color: #201546;
  font-weight: 500;
}
::v-deep span.wpContent > h2 > strong {
  font-weight: 500!important;
}
.search {
  background-color: #e50451;
  border-radius: 0px 33px 33px 0px;
  width: 138px;
  height: 56px;
}
.custom-select {
  height: 56px;
}
.icon {
  vertical-align: -0.8em;
}
@media only screen and (max-width: 990px) {
  #content {
    padding-left: 0%;
  }
}
</style>
