<template>
  <div class="ourContainer">
    <b-row>
      <b-col v-for="(pdf, index) in resourceFilter" :key='index' class="disCol" lg="4" md="12">
        <b-nav-item :href="pdf.acf.pdf_url">
          <div>
            <b-img
              :src="pdf.acf.image"
            ></b-img>
            <br />
            <div class="title" :class="Cat">
              <span class="db">{{pdf.acf.title}}</span>
            </div>
          </div>
        </b-nav-item>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "Important Documents",
  components: { 

  },
  data() {
    return {
      page: [],
      parentId: "",
      resources:[]
    };
  },
  computed: {
    Cat: function(cat, rn) {
      var sp = this.$route.name.split("-");
      if (sp.length > 2) {
        rn = [sp[0] + "-" + sp[1]];
      } else {
        rn = sp;
      }
      if (rn[0] === "news") {
        cat = "none";
      } else {
        cat = rn[0];
      }
      return cat;
    },
    resourceFilter:function(){
      return this.resources.filter((pdf) =>
          pdf.acf.category.includes(this.Cat));
    }
  },
  methods: {
    async Dis() {
      var rn = this.Cat;
      if (rn === "none") {
        apiCall
          .wpInfo("wp/v2/pages?slug=" + rn)
          .then(({ data }) => {
            this.page = data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            throw error;
          });
      } else {
        try {
          let first_call = await apiCall.wpInfo("wp/v2/important-docs-pdf");
          this.resources = first_call.data;
          this.loading = false;
        } catch (error) {
          console.log("error in await calls");
          console.log(error);
          throw error;
        }
      }
    },
  },
  mounted() {
    this.Dis();
  },
};
</script>

<style scoped>
#content {
  padding-left: 20%;
}
.disCol > .nav-item > .nav-link {
  padding: 0;
}
.disCol {
  padding: 0 5px 50px 5px;
}
.title {
  height: 66px;
  border-radius: 0px 33px 33px 0px;
  font-size: 16px;
  color: #fff;
  margin-top: -33px;
  position: absolute;
  padding: 20px;
  text-transform: uppercase;
  padding-right: 75px;
  -webkit-transition: padding-right 0.5s;
  transition: padding-right 0.5s;
}
.title:hover {
  padding-right: 140px;
  font-size: 14px;
}
.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.wp {
  padding-top: 50px;
}
.title.swimming-important {
  background-color: #009fe3;
}
.title.para-swimming {
  background-color: #afca0b;
}
.title.water-polo {
  background-color: #349980;
}
.title.masters-important {
  background-color: #ff9901;
}
.title.artistic-swimming {
  background-color: #f80240;
}
.title.diving-important {
  background-color: #6ec9df;
}
.title.open-water {
  background-color: #5c439c;
}
@media only screen and (max-width: 990px) {
  #content {
    padding-left: 0;
  }
  ::v-deep .wp {
    padding-top: 10px!important;
  }
}
</style>
