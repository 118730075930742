<template>
  <div>
    <div id="content">

      <!-- <div v-bind:style="{ 'background-image': 'url(' + profile[0].acf.top_banner_image + ')' }"> -->
      <div class="top">
      </div>
      <div>
        <div class="ourContainer">
          <b-row>
            <b-col>
              <a href="/about-us/governance/hall-of-fame/">
                <font-awesome-icon class="icon leftarrow" :icon="['fa', 'chevron-left']"/>
                <span class="backtoProfiles">Back To Profiles</span>
              </a>
            </b-col>
            <b-col>
              <div class="outerImage">
                <img class="profileImage" :src="profile[0].acf.image">
              </div>
            </b-col>
          </b-row>
          <div class="proDetails">
            <h2 class="profileTitle">{{profile[0].acf.title}} <span class="disciplineTitle"> / {{profile[0].acf.discipline}} </span></h2>
          </div>
          <div class="topSectionText">
            <p class="topSectionText" v-html="profile[0].acf.top_sub_text"></p>
          </div>
          <div class="sectionMargin">
            <div class="infoBlock">
              <b-row class="infoBlockRow">
                <b-col>
                  <span class="infoBlockTitle">Date Of Birth:</span>
                  <span v-if="profile[0].acf.date_of_birth.length" class="infoBlockDescript">{{ profile[0].acf.date_of_birth }}</span>
                  <span v-else class="infoBlockDescript">N/A</span>
                </b-col>
                <b-col>
                  <span class="infoBlockTitle">Place Of Birth:</span>
                  <span v-if="profile[0].acf.place_of_birth.length > 0" class="infoBlockDescript">{{ profile[0].acf.place_of_birth }}</span>
                  <span v-else class="infoBlockDescript">N/A</span>
                </b-col>
              </b-row>
              <b-row class="mobileinfosecondBlock">
                <b-col>
                  <span class="infoBlockTitle">Discipline:</span>
                  <span v-if="profile[0].acf.discipline.length > 0" class="infoBlockDescript">{{ profile[0].acf.discipline }}</span>
                  <span v-else class="infoBlockDescript">N/A</span>
                </b-col>
                <b-col>
                  <span class="infoBlockTitle">Inducted To Hall Of Fame:</span>
                  <span v-if="profile[0].acf.induction.length > 0" class="infoBlockDescript">{{ profile[0].acf.induction }}</span>
                  <span v-else class="infoBlockDescript">N/A</span>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="sectionMargin">
            <span v-html="profile[0].content.rendered"></span>
            <div class="achievementsPad" v-if="profile[0].acf.profile_ach.length > 0">
              <b-card
                no-body
                class="mb-1"
              >
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle="'one'"
                    :id="index"
                    class="faqQ"
                    >
                    <span class="recordsTitle">
                      Achievements
                    </span>
                    <span class="arrow" >
                      <span class="plusIcon">
                        <font-awesome-icon class="icon" :icon="['fa', 'plus']"/>
                      </span>
                    </span>
                  </b-button>
                </b-card-header>
                <b-collapse
                  :id="'one'"
                  :accordion="'one'"
                  role="tabpanel"
                >
                  <b-card-body class="profileCard">
                    <b-card-text v-html="profile[0].acf.profile_ach"></b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
        <div class="ourContainer">
          <div class="sectionMargin">
            <h2 class="profileTitle galleryText">More Profiles</h2>
          </div>
          <b-row class="moreProfiles">
            <b-col
            v-for="(other, index) in profileList.slice(0,3)"
            :key="index"
            cols="12"
            lg="4"
            md="4"
            class="mobileOtherProfiles"
            >
              <div>
                <div>
                  <div class="outerImageProfiles">
                    <img v-if="other.acf.image.length > 0" class="profileImage" :src="other.acf.image">
                    <img v-else class="profileImageDummy" src="https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/03/966-9665317_placeholder-image-person-jpg.jpg">
                  </div>
                </div>
                <b-nav-item :to="'/about-us/history-of-our-sport/hall-of-fame/' + other.slug">
                <b-card
                  :title='other.acf.title'
                  img-alt="Image"
                  img-top
                  tag="article"
                  class="mb-2"
                >
                  <b-card-text class="cardInfo">
                    <span class="otherRep">{{other.acf.represents}}</span>
                  </b-card-text>
                </b-card>
                </b-nav-item>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>   
    <Footer /> 
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
import Footer from "@/components/footer.vue";
export default {
  name: "profilePage",
  props: ["slug", "dis"],
  components:{
    Footer
  },
  data() {
    return {
      profile: [],
      profileList: [],
    };
  },
  mounted() {
    apiCall
      .wpInfo("wp/v2/hall-of-fame?slug=" + this.slug)
      .then(({ data }) => {
        this.profile = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  created() {
      apiCall
      .wpInfo("wp/v2/hall-of-fame?_embed&per_page=100")
      .then(({ data }) => {
        this.profileList = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }
};
</script>

<style scoped>
.card-header:first-child {
  padding: 0px!important;
  border-bottom: 0px solid rgba(0, 0, 0, 0.125);
}
.card {
  border: 0px solid rgba(0, 0, 0, 0.125);
}
.achievementsPad {
  padding-top: 30px;
  padding-bottom: 30px;
}
.profileImageDummy {
  margin-top: 18px;
}
.moreProfiles {
  margin-top: 12em;
}
.otherRep {
  text-transform: uppercase;
  font-size: 8pt;
}
.card-body {
  background: #eff8fc;
  height: 150px;
  padding-top: 80px;
}
.profileCard {
  height: auto;
  padding-top: 1.25rem;
}
p.cardInfo > div {
  text-align: center;
  display: inline-flex!important;
  margin: auto;
  margin-top: 20px;
}
p.cardInfo > div > .circle{
  width: 45px;
  height: 45px;
  line-height: 38px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;
  /* color: #fff; */
  text-align: center;
  background: transparent;
  margin-left: 10px;
  margin-right: 10px;
}
h4.card-title {
  text-align: center;
  font-weight: 600;
  font-size: 15pt;
  color: #201546;
}
.cardInfo {
  text-align: center;
}
.photosRow {
  margin-top: 30px;
}
h2.galleryText {
  margin-bottom: 40px;
}
li.nav-item.viewAllNews > a{
  color: #fff;
}
li.nav-item.viewAllNews {
  background: #db044e;
  color: #fff;
  width: 150px;
  border-radius: 8px;
  margin: auto;
  margin-top: 50px;
  text-align: center;
}
li.nav-item.viewAllNews:hover {
  background: rgb(0, 159, 227);
  color: #fff;
  width: 150px;
  border-radius: 8px;
  margin: auto;
  cursor: pointer;
  margin-top: 50px;
}
.ImagePhotoGallery {

}
.photoImage {

}
.outerBluebackground {
  background-color: rgb(170 220 251);
  padding-bottom: 30px;
  padding-top: 10px;
}
.collapsed:after {
  content: 'Open';
  float: right;
  padding-right: 15px;
}
.not-collapsed:after {
  content: 'Close';
  float: right;
  padding-right: 15px;
}
.infoBlockRow {
  margin-bottom: 30px;
}
.infoBlockTitle {
  text-transform: uppercase;
  display: block;
  padding-bottom: 8px;
}
.infoBlockDescript {
  font-weight: 600;
  display: block;
}
.infoBlock {
  background-color: #eff8fc;
  padding: 25px;
}
span.medalName {
  color: #201546;
  font-weight: 100;
  font-size: 9pt;
  text-transform: none;
  display: block;
}
.medalTally {
  display: flex;
  margin-top: 15px;
  margin-left: -9px;
}
.sectionMargin {
  margin-top: 50px;
}
::v-deep p.topSectionText > p {
  color: #201546;
  font-weight: 600;
  font-size: 14pt;
  margin-top: 50px;
}
.topSectionText {
  color: #201546;
  font-weight: 500;
}
.socialImagePro {
  width: 35px;
}
.socialLinksPro {
  margin-top: 50px;
  display: block;
  margin-left: -5px;
}
a.twitterLink {
  color: #201546;
  text-decoration: underline;
  font-size: 10pt;
  position: relative;
  top: -2px;
}
.disciplineTitle {
  font-weight: 100;
  font-size: 10pt;
  text-transform: uppercase;
}
.proDetails {
  margin-top: 60px;
}
.profileTitle {
  font-size: 30px;
  color: #201546ff;
  font-weight: bold;
}
.outerImageProfiles {
  height: 170px;
  width: 170px;
  background: #ececec;
  border-radius: 100px;
  overflow: hidden;
  position: absolute;
  right: 0px;
  left: 0px;
  top: -125px;
  margin: auto;
  z-index: 9;
}
.outerImage {
  height: 170px;
  width: 170px;
  background: #ececec;
  border-radius: 100px;
  overflow: hidden;
  position: absolute;
  right: 30px;
  top: -125px;
}
.profileImage {

}
.backtoProfiles {
  color: #201546;
}
svg.icon.leftarrow.svg-inline--fa.fa-chevron-left.fa-w-10 {
  margin-left: -12px;
  color: #201546;
}
.DropBut {
  background-color: white;
  color: #009fe3;
  font-style: italic;
  border: 0;
  text-align: left;
}
.accordion > .card.drop {
  border-top: 1px solid!important;
  border:none;
  border-radius: 0px;
}
.accordion > .card.drop > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
  border: 0px;
  box-shadow: 0px;
  padding: 0px!important;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #1f1545!important;
  background-color: #eff8fc!important ;
  border-color: #eff8fc!important;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}
.faqQ {
  background-color: transparent;
  border: 0;
  text-align: left;
  color: #1f1545;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/openbg.jpg);
  text-align: left;
  font-weight: 600;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 13pt;
  background-repeat: no-repeat;
  background-position: right;
}
.faqQ:hover {
  background-color: transparent;
  border: 0;
  text-align: left;
  color: #1f1545;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/openbgHover.jpg);
  text-align: left;
  font-weight: 600;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 13pt;
  background-repeat: no-repeat;
  background-position: right;
}
span.arrow {
  float: right;
  display: grid;
  text-align: center;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #fff;
  border-color: #545b62;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%);
}
#content {
  padding-left: 20%;
}
.mobShow{
  display: none;
}
.top {
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Main_Profile_Background@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
}
.mobileTop {
  display: none;
}
.route {
  display: inline-flex;
}
.route > li > a {
  padding: 0.5rem 0;
  text-transform: uppercase;
  font-weight: bold;
}
.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.circle {
  width: 55px;
  height: 55px;
  line-height: 46px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  background: transparent;
  margin-left: 10px;
  margin-right: 10px;
}
.gold {
  border: solid 4px #ad883b;
  color: #ad883b;
}
.silver {
  border: solid 4px #8d8d8d;
  color: #8d8d8d;
}
.bronze {
  border: solid 4px #9d5f0b;
  color: #9d5f0b;
}
.name {
  color: #fff;
  font-size: 50px;
  text-transform: uppercase;
  padding-bottom: 71px;
  font-weight: bold;
}
.subT {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}
.value {
  color: #fff;
  font-size: 19px;
}
.value.swimming {
  color: #009fe3;
}
.value.para-swimming {
  color: #afca0b;
}
.value.water-polo {
  color: #349980;
}
.value.masters {
  color: #ff9901;
}
.value.artistic-swimming {
  color: #f80240;
}
.value.diving {
  color: #6ec9df;
}
.value.open-water {
  color: #5c439c;
}
.info {
  margin-top: 120px;
  margin-left: 100px;
}
.line {
  border: 5px solid #ffffff;
  opacity: 0.05;
  margin-bottom: 39.5px;
}
.icon {
  width: 36px;
  /* margin-right: 10px; */
}
::v-deep .wpContent > h2 {
  padding-bottom: 30px;
  color: #201546;
  font-size: 24px;
  font-weight: 500;
}
::v-deep .wpContent > h2 > strong{
  font-weight: 500!important;
}
::v-deep .wpContent > h3 {
  padding-bottom: 30px;
  color: #201546;
}
::v-deep table { 
  width: 100%;
}
::v-deep th {
  background: #009fe3 0% 0% no-repeat padding-box;
  color: #fff;
  padding: 20px 16px 19px 21px;
}
::v-deep td {
  border: 4px solid #fff;
  padding: 17px 18px 17px 21px;
  font-size: 14px;
  color: #707070;
  width: 7%;
}

::v-deep tr {
  background-color: #f7f7f7;
}
::v-deep .wpContent > p:first-child {
  color: #009fe3;
  font-weight: bold;
}
.wp {
  padding-top: 50px;
}
::v-deep .wp-block-image > img {
  object-fit: cover;
}
.hide0 {
  display: none;
}
.nav-item > a {
  color: #009fe3;
}
.profilePic {
  width: 55%;
  position: absolute;
  bottom: -168px;
  right: 0;
}
::v-deep .swimming > figure > table >thead > tr > th{
  background: #009fe3;
}
::v-deep .para-swimming > figure > table >thead > tr > th {
  background: #afca0b;
}
::v-deep .water-polo > figure > table >thead > tr > th {
  background: #349980;
}
::v-deep .masters > figure > table >thead > tr > th {
  background: #ff9901;
}
::v-deep .artistic-swimming > figure > table >thead > tr > th {
  background: #f80240;
}
::v-deep .diving > figure > table >thead > tr > th {
  background: #6ec9df;
}
::v-deep .open-water > figure > table >thead > tr > th {
  background: #5c439c;
}
@media only screen and (max-width: 1280px) {
  .name {
    color: #fff;
    font-size: 40px;
    text-transform: uppercase;
    padding-bottom: 71px;
    font-weight: bold;
  }
}
@media only screen and (max-width: 1335px) {
  .profilePic {
    bottom: -0px;
  }
}
@media only screen and (max-width: 1287px) {
  .info {
    margin-top: 112px;
    margin-left: 100px;
  }
}
@media only screen and (max-width: 1280px) {
  .name {
    color: #fff;
    font-size: 40px;
    text-transform: uppercase;
    padding-bottom: 71px;
    font-weight: bold;
  }
}
@media only screen and (max-width: 1076px) {
  .info {
    margin-top: 50px;
    margin-left: 100px;
  }
} 
@media only screen and (max-width: 990px) {
  ::v-deep .profilePic {
    width: 80%!important;
    position: absolute;
    bottom: 0;
    right: 16px;
  }
  ::v-deep .splash {
    display: none!important;
  }
  ::v-deep .wp {
    padding-top: 10px!important;
  }
  #content {
    padding-left: 0;
  }
  .top {
    display: none;
  }
  .mobShow {
    display: block;
  }
  .mobileTop {
    background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/Main_Profile_Background@2x.png);
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    margin-bottom: 20px;
  }
  .info {
    margin-top: 20px;
    margin-left: 20px;
  }
  .name {
    font-size: 18pt;
    padding-bottom: 0;
  }
  .profilePic{
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 16px;
  }
}
@media only screen and (max-width: 500px) {
  .outerImage {
    height: 170px;
    width: 170px;
    background: #ececec;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    right: 0;
    top: 0;
    left: 0;
  }
  .infoBlockRow{
    margin-bottom: 30px;
    display: block;
  }
  .mobileinfosecondBlock {
    display: block;
  }
  .infoBlockTitle {
    padding-bottom: 0px;
  }
  .mobileOtherProfiles {
    margin-bottom: 150px;
  }
}
</style>
