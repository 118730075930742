<template>
  <div>
    <div id="content">
      <div class='ourContainer'>
        <Disciplines />
      </div>
    </div>
    <Footer />    
  </div>

</template>

<script>
import Disciplines from "@/components/disciplines.vue"
import Footer from '@/components/footer.vue';
export default {
  name: "disciplines",
  components:{Disciplines, Footer},
  metaInfo: {
    title: "Our Sports"
  },
};
</script>

<style scoped>
#content {
  padding-left: 20%;
}

.ourContainer {
  max-width: 1350px; 
  margin: 40px auto;
  padding: 0 30px;
  
}
@media only screen and (max-width: 990px) {
  #content {
  padding-left: 0%;
}
}
@media only screen and (max-width: 500px) {
  ::v-deep   .ourContainer {
    max-width: none;
    margin: 0px auto;
    padding: 0 0px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
