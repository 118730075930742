<template>
  <div>
    <template v-if="siteMap === true" name="slide" appear>
      <b-nav-item :class="{sm: siteMap === true}" :to="'/' + itemLink(item.title)" name="slide" appear >
        {{ item.title }}
      </b-nav-item>

      <b-nav-item
        name="slide" appear
        class="sub"
        :class="{sm: siteMap === true}" 
        v-for="(child, index) in children"
        :key="index"
        :to="'/' + itemLink(item.title) + '/' + child.slug"
        >{{ titleHTML(child.title.rendered) }}</b-nav-item
      >
    </template>
    <template v-else>
      <transition name="slide" appear v-if="item.title === 'Jobs' || item.title === 'News'">

        <b-nav-item v-if="isMobile(true)"  :to="'/' + itemLink(item.title)" @click="oOrC(), $bvModal.hide('nav-collapse')" v-show="item.title !== 'Our Sports' && item.title !== 'Teaching and Coaching' && item.title !== 'Clubs' && item.title !== 'Learn To Swim'">
          {{ item.title }}
        </b-nav-item> 

        <b-nav-item v-else :to="'/' + itemLink(item.title)" @click="oOrC()" v-show="item.title !== 'Our Sports' && item.title !== 'Teaching and Coaching' && item.title !== 'Clubs' && item.title !== 'Learn To Swim'">
          {{ item.title }}
        </b-nav-item>


      </transition>
      <transition name="slide" appear v-else>

        <b-nav-item :to="'/' + itemLink(item.title)" @click="oOrC()" v-show="item.title !== 'Our Sports' && item.title !== 'Teaching and Coaching' && item.title !== 'Clubs' && item.title !== 'Learn To Swim'">
          {{ item.title }}
        </b-nav-item>


      </transition>
      <template v-if="show === true">
        <!-- <transition-group name="slide" appear v-show="item.title !== 'Coaching and Teaching' && item.title !== 'Clubs' && item.title !== 'Learn To Swim'" >
        <b-nav-item
          @click="$bvModal.hide('nav-collapse')"
          class="sub"
          v-for="(child, index) in children.slice().reverse()"
          :key="index"
          :to="'/' + itemLink(item.title) + '/' + child.slug"
          >{{ titleHTML(child.title.rendered) }} four</b-nav-item
        ></transition-group> -->


        <transition name="slide" appear tag="ul" class="DONTSHOW" v-show="item.title !== 'Teaching and Coaching' && item.title !== 'Clubs' && item.title !== 'Learn To Swim'">
          <b-nav-item name="slide" appear tag="ul" class="sub DONTSHOW" v-for="(child, index) in children.slice(0).reverse()" :key="index">
            {{ titleHTML(child.title.rendered) }}
          </b-nav-item>
        </transition>
        <transition-group name="slide" appear v-show="item.title !== 'Teaching and Coaching' && item.title !== 'Clubs' && item.title !== 'Learn To Swim'">
          <b-nav-item :to="'/' + itemLink(item.title) + '/' + child.slug" name="slide" appear tag="ul" @click="$bvModal.hide('nav-collapse')" class="sub" v-for="(child, index) in children.slice().reverse()" :key="index">
            {{ titleHTML(child.title.rendered) }}
          </b-nav-item>
        </transition-group>
      </template>
    </template>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "linkItems",
  props: ["item", "siteMap"],
  data() {
    return {
      children: [],
    };
  },
  computed: {
    route: function() {
      var r = this.$route.fullPath.split("/");
      if (r[0] === "") {
        r[0] = "home";
      }
      return r;
    },
    show:function(show){
      var titlelow = this.item.title.toLowerCase()
      var title = titlelow.replace(/ /g, "-")
      if(this.siteMap === true){
        show = true
      }else {
      if(this.route[1] === title){
        show = true
      }
      else show === false
    }
      return show
    }
  },
  methods: {
    oOrC() {
      var titlelow = this.item.title.toLowerCase();
      var title = titlelow.replace(/ /g, "-");
      if (this.route[1] === title) this.show = true;
      else this.show = false;
    },
    itemLink(item) {
      var low = item.toLowerCase();
      var rep = low.replace(/ /g, "-");
      return rep;
    },
    titleHTML(item) {
      var low = item;
      var rep = low.replace(/&#038;/g, "&");
      return rep;
    },
    isMobile() {
        if( screen.width <= 760 ) {
            return true;
        }
        else {
            return false;
        }
    },
  },
  mounted() {
    apiCall
      .wpInfo("wp/v2/pages?parent=" + this.item.object_id + "&per_page=10")
      .then(({ data }) => {
        this.children = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
::v-deep .DONTSHOW > a{
  color: transparent!important;
  padding: 0px;
}
::v-deep .DONTSHOW > a:hover{
  color: transparent!important;
  padding: 0px;
}
::v-deep .DONTSHOW + span{
  margin-top: -30px;
  display: block;
}
::v-deep ul.dropdown-menu.show {
  position: inherit !important;
  transform: translate3d(55px, 0px, 0px) !important;
  background-color: #201546;
  color: #fff;
}
a {
  color: #fff !important;
}
::v-deep a > span {
  color: #fff !important;
}
::v-deep a > span {
  color: #fff !important;
}
::v-deep .nav-link {
  text-transform: capitalize;
}
.sub {
  padding-left: 20px;
}
.sub > a {
  font-size: 16px;
}
.router-link-exact-active {
  color: #009fe3 !important;
}
a:hover{
  color: #009fe3 !important;
  /* font-weight: bold; */
}
.sm>a{
  color: #009fe3 !important;
}
.nav-link .router-link-exact-active .router-link-active{
  color: #009fe3;
}
::v-deep.slide-enter-active {
   -moz-transition-duration: 1s;
   -webkit-transition-duration: 1s;
   -o-transition-duration: 1s;
   transition-duration: 1s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

::v-deep .slide-leave-active {
   -moz-transition-duration: 1s;
   -webkit-transition-duration: 1s;
   -o-transition-duration: 1s;
   transition-duration: 1s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

::v-deep .slide-enter-to, .slide-leave {
   max-height: 100px;
   overflow: hidden;
}

::v-deep .slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>
