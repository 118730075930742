<template>
  <div>
    <div id="content">
      <Splash :bannerImage="page[0].acf.banner_image"/>
      <div class="ourContainer">
        <!-- <Route /> -->
        <div class="wp">
          <!-- <h1 class="SportsHead">{{this.page[0].title.rendered}}</h1> -->
          <b-row>
            <b-col :class="{ 'col-lg-9 col-md-9': page[0].acf.right_sidebar === true }"
              ><span class="wpContent" v-html="page[0].content.rendered"></span
            ></b-col>
            <!-- Right Side Bar -->
            <template v-if="page[0].acf.right_sidebar === true">
              <b-col>
                <!-- Ads -->
                <template v-if="page[0].acf.ad_links.length">
                  <SideBarBlock
                    v-for="(link, index) in page[0].acf.ad_links"
                    :key="index"
                    type="ad"
                    :image="link.image"
                    :url="link.url"
                  />
                </template>
                <!-- End Ads -->

                <!-- Downloads -->
                <template v-if="$route.path === '/our-sports/swimming/records'">
                  <template v-if="page[1].acf.downloads.length">
                    <div class="downloadsLink">
                      <h4 class="sideTitle">
                        <template v-if="page[1].acf.downloads_header_text == true ">
                          <h4 class="sideTitle downTop">
                            {{page[1].acf.downloads_header_text}}
                            <hr class="hrLine">
                          </h4>
                        </template>
                        <template v-else>
                          <h4 class="sideTitle downTop">
                            Downloads
                            <hr class="hrLine">
                          </h4>
                        </template>
                      </h4>
                      <SideBarBlock
                        v-for="(link, index) in page[1].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>
                <template v-else-if="$route.path === '/our-sports/para-swimming/records'">
                  <template v-if="page[3].acf.downloads.length">
                    <div class="downloadsLink">
                      <template v-if="page[3].acf.downloads_header_text == true ">
                        <h4 class="sideTitle downTop">
                          {{page[3].acf.downloads_header_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle downTop">
                          Downloads
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[3].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>
                <template v-else-if="$route.path === '/our-sports/masters/records'">
                  <template v-if="page[2].acf.downloads.length">
                    <div class="downloadsLink">
                      <template v-if="page[2].acf.downloads_header_text == true ">
                        <h4 class="sideTitle downTop">
                          {{page[2].acf.downloads_header_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle downTop">
                          Downloads
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[2].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>
                <template v-else>
                  <template v-if="page[0].acf.downloads.length">
                    <div class="downloadsLink">
                      <template v-if="page[0].acf.downloads_header_text == true ">
                        <h4 class="sideTitle downTop">
                          {{page[0].acf.downloads_header_text}}
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <template v-else>
                        <h4 class="sideTitle downTop"> Downloads 
                          <hr class="hrLine">
                        </h4>
                      </template>
                      <SideBarBlock
                        v-for="(link, index) in page[0].acf.downloads"
                        :key="index"
                        type="pdf"
                        :title="link.title"
                        :url="link.download_url"
                      />
                    </div>
                  </template>
                </template>                  

                <!--End Downloads -->

                <!-- URL Links -->
                <template v-if="page[0].acf.useful_links.length">
                  <div class="usefulLinks">
                    <template v-if="page[0].acf.usefull_links_text == true">
                      <h4 class="sideTitle top">
                        {{page[0].acf.usefull_links_text}}
                        <hr class="hrLine">
                      </h4>
                    </template>
                    <template v-else>
                      <h4 class="sideTitle top">
                        Useful Links
                        <hr class="hrLine">
                      </h4>
                    </template>
                    <SideBarBlock
                      v-for="(link, index) in page[0].acf.useful_links"
                      :key="index"
                      type="link"
                      :title="link.title"
                      :url="link.url"
                    />
                  </div>
                </template>                  

                <!-- End URL Links -->
              </b-col>
            </template>
            <!-- End Right Side Bar -->
          </b-row>
        </div>
      </div>
    </div>   
    <Footer /> 
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
import Splash from '@/components/splash.vue'
import Footer from '@/components/footer.vue';
// import Route from "@/components/route.vue";
export default {
  name: "events",
 components:{Splash, Footer},
  data() {
    return {
      page: [],
      parentId: "",
      newsPage: true,
      title: '',
      description:'',
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  computed: {
    Cat: function(cat, rn) {
      var sp = this.$route.name.split("-");
      if (sp.length > 2) {
        rn = [sp[0] + "-" + sp[1]];
      } else {
        rn = sp;
      }
      if (rn[0] === "news") {
        cat = "none";
      } else {
        cat = rn[0];
      }
      return cat;
    },
  },
  methods: {
    async Dis() {
      var rn = this.Cat;
      if (rn === "none") {
        apiCall
          .wpInfo("wp/v2/pages?slug=" + rn)
          .then(({ data }) => {
            this.page = data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            throw error;
          });
      } else {
        try {
          // First call, await for call to return
          let first_call = await apiCall.wpInfo("wp/v2/pages?slug=" + rn);
          this.parentId = first_call.data[0].id;
          this.loading = false;
          // Second call, await for call to return
          let second_call = await apiCall.wpInfo(
            "wp/v2/pages?slug=contacts" + "&parent=" + this.parentId
          );
          this.page = second_call.data;
          this.title = second_call.data[0].title.rendered;
          this.description = second_call.data[0].yoast_head_json.og_description;
          this.loading = false;
        } catch (error) {
          console.log("error in await calls");
          console.log(error);
          throw error;
        }
      }
    },
  },
  mounted() {
    this.Dis();
  },
};
</script>

<style scoped>
li.nav-item.linkText {
  line-height: 18px;
}
hr.hrLine {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  border-top: 1px solid #1f1545;
}
h4.sideTitle.downTop {
  margin-top: -10px;
}
::v-deep .sideTitle {
  color: #201546;
  font-size: 17px;
  font-weight: 100;
  padding-bottom: 20px;
  padding: 15px 0px 20px 0;
  padding-top: 20px;
  font-family: GT-Haptik-Medium;
  margin-bottom: -15px;
}
.downloadsLink {
  background-color: #cbebf9;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
  border-radius: 5px;
  padding-right: 10px;
  margin-top: 10px;
}
.usefulLinks {
  background-color: #cbebf9;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 20px;
  margin-top: 10px;
  border-radius: 5px;
  padding-right: 10px;
}
::v-deep span.wpContent > h1 {
  color: #201546;
  text-transform: capitalize;
  /* font-weight: bold; */
  font-family: GT-Haptik-Medium;
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 30px;
  display: block;
}
::v-deep span.wpContent > h1 > strong {
  color: #201546;
  text-transform: capitalize;
  /* font-weight: bold; */
  font-family: GT-Haptik-Medium;
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 30px;
  display: block;
}
h1.SportsHead {
  color: #1f1545;
  padding-bottom: 20px;
  margin-left: -13px;
}
::v-deep span.wpContent > ul > li {
  list-style: disc!important;
}
#content {
  padding-left: 20%;
}
.overlay {
  background-color: #201546;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/HomePage_Overlay_1@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.splash {
  opacity: 0.46;
  height: 603px;
  object-fit: cover;
}
.title {
  position: absolute;
  top: 277px;
  width: 100%;
  text-align: center;
  height: 75px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000058;
  font-weight: bold;
  font-size: 3.5rem;
}
.route {
  display: inline-flex;
}
.route > li > a {
  padding: 0.5rem 0;
  text-transform: uppercase;
  font-weight: bold;
}
.ourContainer{
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.wp {
  padding-top: 50px;
}
::v-deep .wpContent > h2 {
  padding: 21px 0;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Lessfussy.jpg);
  /* background-position: right; */
  background-position: initial;
  padding-left: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  background-repeat: no-repeat;
  background-size: contain;
}
::v-deep .wpContent > h2 > strong{
  font-weight: 500!important;
}
::v-deep .wpContent > p {
  color: #201546;
  font-size: 16px;
}
::v-deep .wpContent > figure {
  padding-top: 20px;
}
::v-deep table{
  width: 100%;
}
::v-deep td{
  border: 4px solid #fff;
  padding: 20px 16px 19px 21px;
  font-size: 14px;
  color: #201546;
}

::v-deep tr {
  background-color: #F7F7F7;
}
.hide0{
  display: none;
}
::v-deep .wpContent > ul >li >a{
  color: #009fe3;
}
::v-deep .wpContent>p>a{
  color: #009fe3;
}
::v-deep .wpContent>figure>table>tbody>tr>td>a{
  color: #009fe3;
}
@media only screen and (max-width: 990px) {
  #content {
    padding-left: 0;
  }
  ::v-deep .wp {
    padding-top: 10px!important;
  }
  span.wpContent {
    padding: 25px 0px;
    display: block;
  }
  ::v-deep .wpContent > h2 {
    padding: 21px 0;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Lessfussy.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center!important;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (max-width: 768px) {
  span.wpContent {
    padding: 25px 0px;
    display: block;
  }
  ::v-deep .wpContent > figure {
    padding-top: 20px;
    overflow: scroll;
  }
  ::v-deep .wp-block-columns {
    overflow: scroll;
  }
}
</style>
