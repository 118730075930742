<template>
  <div>

    <b-embed
      type="embed"
      aspect="1by1"
      :src="'https://live-scotswim-full.ocs-software.com/dynamic-forms/?form_id=' + page[0].acf.form.id"
    ></b-embed>

    <!-- <iframe
      v-resize="{ log: true }"
      width="100%"
      :src="'https://live-scotswim-full.ocs-software.com/dynamic-forms/?form_id=' + page[0].acf.form.id"
      frameborder="0"
    ></iframe> -->
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import axios from "axios";
import addOAuthInterceptor from "axios-oauth-1.0a";
export default {
  name: "Form",
  props: ["slug"],
  data() {
    return {
      page: [],
      sent: false,
      data: [],
      form_data: {},
      form: {},
      form_message: ""
    };
  },
  
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.wpInfo(
        "wp/v2/pages?slug=" + this.itemLink(this.$route.name)
      );
      this.page = first_call.data;
      this.form = first_call.data[0].acf.form;
      let message_temp = this.form.confirmations;
      this.form_message = message_temp[Object.keys(message_temp)[0]];
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
  methods: {
    
    itemLink(item) {
      var low = item.toLowerCase();
      var rep = low.replace(/ /g, "-");
      return rep;
    },
    submitForm() {
      // Create a client whose requests will be signed
      const client = axios.create();

      // Specify the OAuth options
      const options = {
        algorithm: "HMAC-SHA1",
        key: "ck_2f7b184c918a9622fb22c2da17fd6a8ac0bf4bc2",
        secret: "cs_63cf4accd4cd42cc391fdd42f956d0a1fb17096f"
      };

      // Add interceptor that signs requests
      addOAuthInterceptor(client, options);
      let form_post = {
        form_id: this.form.id,
        is_starred: 0,
        is_read: 0,
        status: "active"
      };
      for (const key in this.form_data) {
        form_post[key] = this.form_data[key];
      }
      client
        .post(
          "https://live-scotswim-full.ocs-software.com/wp-json/gf/v2/entries",
          form_post
        )
        .then(response => {
          //Perform Success Action
          console.log("Line 66");
          this.sent = true;
          return response;
        })
        .catch(error => {
          // error.response.status Check status code
          console.log("Line 72");
          return error;
        })
        .finally(() => {
          //Perform action in always
          console.log("Line 73");
        });
    }
  }
};
</script>

<style scoped>
  iframe {
    width: 1px;
    min-width: 100%;
  }
/* input.fieldSize {
  width: 100%;
  height: 50px;
}
.boxSpace {
  margin-bottom: 20px;
} */
</style>