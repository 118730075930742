<template>
  <div>
    <div id="content">
      <Splash
        :bannerImage="page[0].acf.banner_image"
      />
      <div class="HideTileDesk">
        <Tileshow />
      </div>
      <div :class="'backgroundMain-' + page[0].slug">
        <div class="ourContainerDisMain">
          <template v-if="page[0].slug === 'open-water' || page[0].slug === 'artistic-swimming'">
            <h1 class="mainHead openWater" v-html="page[0].title.rendered"></h1>
            <p class="mainSection mainSecWidth openWater" v-html="page[0].acf.sport_descript"></p>
          </template>
          <template v-else>
            <h1 class="mainHead" v-html="page[0].title.rendered"></h1>
            <p class="mainSection mainSecWidth" v-html="page[0].acf.sport_descript"></p>
          </template>
        </div>
      </div>
      <b-row class="ourSportDescript">
        <b-col cols="12" lg="6" md="6" class="sportContentImage" v-bind:style="{ 'background-image': 'url(' + page[0].acf.sport_content_image + ')' }">
        </b-col>
        <b-col cols="12" lg="6" md="6">
          <div class="contentarea">
            <span class="wpContent textContentWidth" v-html="page[0].content.rendered"></span>
          </div>
        </b-col>
      </b-row>
      <div v-show="page[0].acf.video_block_active === true" class="ourContainerDisMain">
          <h1 class="mainHead">{{page[0].title.rendered}} Videos</h1>
          <b-row class="">
            <b-col cols="12" md="4" lg="4" class="sportContentImage"> 
              <div v-html="page[0].acf.videoblock"></div>
            </b-col>
          </b-row>
      </div>
      <div :class="'backgroundBenefits-' + page[0].slug">
        <div class="ourContainerDisMain">
          <h1 class="mainHead" v-html="'The Benefits of ' + page[0].title.rendered"></h1>
          <b-row>
            <b-col cols="12" lg="6" md="6" v-for="(sport, index) in page[0].acf.benefits_of_sport" :key="index">
              <b-card class="CardDepth" :title="sport.title">
                <b-card-text v-html="sport.descript"></b-card-text>
              </b-card>
            </b-col>
            <!-- <b-col cols="12" lg="6" md="6">
              <b-card class="CardDepth" v-for="(sport, index) in page[0].acf.benefits_of_sport.slice(3,6)" :key="index" :title="sport.title">
                <b-card-text v-html="sport.descript"></b-card-text>
              </b-card>
            </b-col> -->
          </b-row>
          <b-row class="viewNewsRow">
            <b-col class="">
              <b-nav-item class="viewAllNews" to="/find-a-club">Find A Club</b-nav-item>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="ourContainerDisMain">
        <News :cat="$route.name" :newsPage="newsPage" />

        <b-row class="viewNewsRow AllNews">
          <b-col class="">
            <b-nav-item class="viewAllNews" to="/news">View All News</b-nav-item>
          </b-col>
        </b-row>

        <!-- Up Coming Events -->
        <h1 class="mainHead" v-html="page[0].title.rendered + ' Events'"></h1>
        <Upcoming :discipline="itemLink(page[0].slug)" total="two" />

        <b-row class="viewNewsRow AllNews">
          <b-col class="EventsSwim">
            <b-nav-item class="viewAllNews" to="/events">View All Events</b-nav-item>
          </b-col>
        </b-row>
        <!-- End Up Coming Events -->
      </div>
      <div class="joinSwimback antiDop" v-bind:style="{ 'background-color': '#2d2dcf' }">
        <div class="container joinSwimBlock">
          <h1>
            <span class="well" v-html="page[0].acf.bottomblock_title">
            </span>
            <span class="Jointag" v-html="page[0].acf.bottomblock_paragraph">
            </span>
          </h1>
          <div class="JoinSwimbuttonsBlock">
            <b-nav-item :to="'/our-sports/' + page[0].slug + '/' + page[0].slug + '-anti-doping'">
              <div class="joinSwimButtons">{{page[0].acf.bottomblock_text}}</div>
            </b-nav-item>
          </div>
        </div>
      </div>

      <!-- <div class="ourContainer">
        <div class="wp">
          <b-row>
            <b-col
              cols="12"
              :class="{ 'col-lg-9 col-md-9' :page[0].acf.right_sidebar === true }"
            >
              <b-row>
                <b-col>
                  <span class="wpContent" v-html="page[0].content.rendered"></span
                ></b-col>
              </b-row>
            </b-col>

            <template v-if="page[0].acf.right_sidebar === true">
              <b-col>
                
                <template v-if="page[0].acf.ad_links.length">
                  <SideBarBlock
                    v-for="(link, index) in page[0].acf.ad_links"
                    :key="index"
                    type="ad"
                    :image="link.image"
                    :url="link.url"
                  />
                </template>

                <template v-if="page[0].acf.downloads.length">
                  <h4 class="sideTitle">
                    Downloads
                  </h4>
                  <SideBarBlock
                    v-for="(link, index) in page[0].acf.downloads"
                    :key="index"
                    type="pdf"
                    :title="link.title"
                    :url="link.download_url"
                  />
                </template>

                <template v-if="page[0].acf.useful_links.length">
                  <h4 class="sideTitle top">
                    Useful Links
                  </h4>
                  <SideBarBlock
                    v-for="(link, index) in page[0].acf.useful_links"
                    :key="index"
                    type="link"
                    :title="link.title"
                    :url="link.url"
                  />
                </template>
              </b-col>
            </template>
          </b-row>

          <template v-if="page[0].acf.health_section === true">
            <Benefits :page="page[0]" />
          </template>

          <Upcoming :discipline="itemLink(page[0].slug)" total="two" />

        </div>
      </div>
      <div class="ourContainer">
        <template v-if="page[0].acf.dropdown_area_show === true" >
          <div style="margin-top:30px">
            <div v-for="(drop, index) in page[0].acf.dropdown_area" :key="index">
              <h4 style="font-weight:bold; margin-bottom: 15px; padding-top:30px;">{{drop.section_title}}</h4>              
              <div class="accordion" role="tablist">
                <b-card
                  no-body
                  class="mb-1"
                >
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button
                      block
                      v-b-toggle="'drop' + index"
                      :id="index"
                      class="faqQ"
                      >Click To Expand<span class="arrow"></span
                    ></b-button>
                  </b-card-header>
                  <b-collapse
                    :id="'drop' + index"
                    :accordion="'drop' + index"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text v-html="drop.section_content"></b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>
        </template>
      </div> -->
      
    </div>  
    <Footer />  
  </div>

</template>

<script>
import apiCall from "@/services/api.js";
// import SideBarBlock from "@/components/sidebarBlock.vue";
// import ClubFinder from "@/components/clubFind.vue";
// import Benefits from "@/components/benefits.vue";
import Upcoming from "@/components/upComing.vue";
import Splash from "@/components/splash.vue";
import News from "@/components/news.vue";
import Footer from "@/components/footer.vue";
import Tileshow from "@/components/tileshow.vue";
// import SignUp from "@/components/signUp.vue";
export default {
  name: "main",
  components: {
    Footer,
    // SideBarBlock,
    // Benefits,
    Upcoming,
    // ClubFinder,
    Splash,
    News,
    Tileshow,
    // SignUp,
  },
  data() {
    return {
      page: [],
      parentpage:[],
      newsPage: false,
      title: '',
      description: '',
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  computed: {
    route: function() {
      var r = this.$route.fullPath.split("/");
      if (r[0] === "") {
        r[0] = "home";
      }
      return r;
    },
  },
  methods: {
    itemLink(item) {
      var low = item.toLowerCase();
      var rep = low.replace(/ /g, "-");
      return rep;
    },
    routeTitle(item) {
      var low = item;
      var rep = low.replace(/-/g, " ");
      return rep;
    },
  },

  mounted() {
    apiCall
      .wpInfo("wp/v2/pages?slug=" + this.itemLink(this.$route.name))
      .then(({ data }) => {
        this.page = data;
        this.loading = false;
        this.parentpage = data;
        this.title = data[0].title.rendered;
        this.description = data[0].yoast_head_json.og_description;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
li.nav-item.linkText {
  line-height: 18px;
}
.card-title {
  font-family: GT-Haptik-Medium;
  font-size: 20px;
}
.openWater {
  color: #fff!important;
}
.backgroundMain-swimming {
  background: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Swimming-BG.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
.backgroundMain-para-swimming {
  background: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Para-BG1-.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
.backgroundMain-diving {
  background: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Diving-BG1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
.backgroundMain-open-water {
  background: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Open-Water-BG1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
.backgroundMain-water-polo {
  background: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Water-Polo-BG-1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
.backgroundMain-artistic-swimming {
  background: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/ArtisticBG1v2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
.backgroundMain-masters {
  background: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/MasterBG1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
span.textContentWidth {
  max-width: 650px;
  margin-left: 0px;
}
.card-body {
  height: 180px;
}
span.wpContent {
  padding: 15px;
  display: block;
}
.antiDop {
  background: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/05/Join-Our-Swimming-Community.jpg);
}
.backgroundBenefits-swimming {
  background: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/05/Benefits-of-Swimming.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.backgroundBenefits-para-swimming {
  background: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Para-BG-2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.backgroundBenefits-diving {
  background: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Diving-BG2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.backgroundBenefits-open-water {
  background: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Open-Water-BG2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.backgroundBenefits-water-polo {
  background: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Water-Polo-BG2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.backgroundBenefits-artistic-swimming {
  background: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/ArtisticBG2v2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.backgroundBenefits-masters {
  background: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/MastersBG2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.backgroundMain {
  background: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2022/06/Swimming-BG.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
.EventsSwim {
  margin-top: 30px;
}
::v-deep .disMain {
  padding: 0px;
}
.JoinSwimbuttonsBlock {
  display: inline-flex;
}
.well {
  font-weight: 600;
  font-size: 2.5rem;
  margin-bottom: 30px;
  display: block;
}
.Jointag {
  font-weight: normal;
  font-size: 1.2rem;
  width: 40%;
  padding-bottom: 20px;
  display: block;
  margin: auto;
}
.joinSwimBlock {
  text-align: center;
  color: #fff;
  padding-top: 130px;
}
.joinSwimback {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 500px;
}
.joinSwimButtons:hover{
  background: rgb(0, 159, 227);
  color: #fff;
  width: 150px;
  border-radius: 8px;
  margin: auto;
  padding: 0.5rem 1rem;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
}
.joinSwimButtons{
  background: #db044e;
  color: #fff;
  width: 150px;
  border-radius: 8px;
  margin: auto;
  padding: 0.5rem 1rem;
  margin-right: 5px;
  margin-left: 5px;
  text-align: center;
  font-weight: 600;
}
.AllNews {
  margin-bottom: 5em;
}
.grey > .ourContainer{
  padding: 0px;
}
.grey {
  background-color: transparent;
}
li.nav-item.viewAllNews:hover {
  background: rgb(0, 159, 227);
  color: #fff;
  width: 150px;
  border-radius: 8px;
  margin: auto;
  cursor: pointer;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
}
li.nav-item.viewAllNews > a {
  color: #fff;
}
li.nav-item.viewAllNews:hover > a {
  color: #fff;
}
li.nav-item.viewAllNews {
  background: #db044e;
  color: #fff;
  width: 150px;
  border-radius: 8px;
  margin: auto;
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
}
.CardDepth {
  margin-bottom: 30px;
}
.sportContentImage {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.ourSportDescript {
  background: rgb(238, 252, 255);
}
p.mainSecWidth {
  width: 50%;
}
h1.mainHead {
  color: #1f1545;
  padding-bottom: 40px;
  /* font-weight: 600; */
  font-family: GT-Haptik-Medium;
  color: #1f1545;
  font-size: 2.5rem;
}
p.mainSection {
  color: #1f1545;
  z-index: 999;
}
.sideTitle {
  color: #201546;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
}
.faqQ {
  background-color: transparent;
  color: #009fe3!important;
  font-style: italic;
  border: 0;
  text-align: left;
}
.arrow {
  border: solid #009fe3;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  right: 10px;
  top: 15px;
}
#content {
  padding-left: 20%;
  padding-top: 0px;
}
.overlay {
  background-color: #201546;
  background-image: url(https://ocs-sport.ams3.cdn.digitaloceanspaces.com/scotswim-full/2021/10/HomePage_Overlay_1@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.splash {
  opacity: 0.46;
  height: 603px;
  object-fit: cover;
}
.title {
  position: absolute;
  top: 277px;
  width: 100%;
  text-align: center;
  height: 75px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  letter-spacing: 0px;
  text-shadow: 0px 3px 6px #00000058;
  font-weight: bold;
  font-size: 3.5rem;
}
.route {
  display: inline-flex;
}
.route > li > a {
  padding: 0.5rem 0;
  text-transform: uppercase;
  font-weight: bold;
}
.ourContainer {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
}
.ourContainerDisMain {
  max-width: 1350px;
  margin: 40px auto;
  padding: 0 30px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.wp {
  padding-top: 50px;
}
::v-deep .wpContent > h2 {
  padding: 30px 0;
  color: #201546;
  font-size: 24px;
  font-weight: 500;
}
::v-deep .wpContent > h2 > strong{
  font-weight: 500!important;
}
::v-deep .wpContent > p {
  color: #201546;
  font-size: 16px;
}
::v-deep .wpContent > figure {
  padding-top: 20px;
}
.hide0 {
  display: none;
}
.nav-item > a {
  color: #009fe3;
}
.pad {
  padding-top: 35px;
  margin-top: 35px;
}
::v-deep table {
  width: 100%;
}
::v-deep th {
  background: #009fe3 0% 0% no-repeat padding-box;
  color: #fff;
  padding: 20px 16px 19px 21px;
}
::v-deep td {
  border: 4px solid #fff;
  padding: 17px 18px 17px 21px;
  font-size: 14px;
  color: #707070;
  width: 7%;
}
::v-deep .wpContent > ul > li > a {
  color: #009fe3;
}
::v-deep .wpContent > ul > li > a {
  color: #009fe3;
}
::v-deep tr {
  background-color: #f7f7f7;
}

@media only screen and (min-width: 990px) {
  .HideTileDesk {
    display: none;
  }
}
@media only screen and (max-width: 990px) {
  .contentarea {
    padding: 30px;
  }
  #content {
    padding-left: 0;
  }
  ::v-deep .wp {
    padding-top: 10px!important;
  }
  ::v-deep iframe {
    width: 100%;
  }
  .RHS.col {
    display: flex;
  }
}
@media only screen and (max-width: 500px) {
  .backgroundMain-swimming {
    background-position: calc(100% - -300px) 23%;
  }
  .backgroundMain-diving {
    background-position: calc(100% - -300px) 23%;
  }
  .backgroundMain-open-water {
    background-position: calc(100% - -300px) 23%;
  }
  .backgroundMain-water-polo {
    background-position: calc(100% - -300px) 23%;
  }
  .backgroundMain-artistic-swimming {
    background-position: calc(100% - -300px) 23%;
  }
  .backgroundMain-masters {
    background-position: calc(100% - -300px) 23%;
  }
  .backgroundMain-para-swimming {
    background-position: calc(100% - -300px) 23%;
  }
  p.mainSecWidth {
    width: 100%;
  }
  span.wpContent {
    padding: 0px;
    display: block;
  }
  .Jointag {
    width: 100%;
  }
  .card-body {
    height: auto;
  }
}
</style>
