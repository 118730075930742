<template>

  <div>
    <template v-if="$route.path === '/about-us/history-of-our-sport/hall-of-fame'">
      <!-- <b-row class="HideMob" v-if="$route.path === '/about-us/history-of-our-sport/hall-of-fame'">
        <b-col cols="4">
          <b-input-group>
            <b-form-select
              v-model="selectedType"
              :options="options"
            ></b-form-select>
          </b-input-group>
        </b-col>
        <b-col cols="4"></b-col>
        <b-col cols="4"></b-col>
      </b-row> -->
      <!-- <b-row class="HideDesk" v-if="$route.path === '/about-us/history-of-our-sport/hall-of-fame'">
        <b-col cols="12">
          <b-input-group>
            <b-form-select
              v-model="selectedType"
              :options="options"
            ></b-form-select>
          </b-input-group>
        </b-col>
      </b-row> -->
    </template>
    <template v-else>
      <b-row class="HideMob">
        <b-col cols="4">
          <b-input-group>
            <b-form-select
              v-model="selectedType"
              :options="options"
            ></b-form-select>
          </b-input-group>
        </b-col>
        <b-col cols="4"></b-col>
        <b-col cols="4"></b-col>
      </b-row>
      <b-row class="HideDesk">
        <b-col cols="12">
          <b-input-group>
            <b-form-select
              v-model="selectedType"
              :options="options"
            ></b-form-select>
          </b-input-group>
        </b-col>
      </b-row>
    </template>
    <b-row class="HideMob" v-if="$route.path === '/about-us/history-of-our-sport/hall-of-fame'">
      <b-col cols="4">
        <b-input-group>
          <b-form-select
            v-model="selectedType"
            :options="options"
          ></b-form-select>
        </b-input-group>
      </b-col>
      <b-col cols="4"></b-col>
      <b-col cols="4"></b-col>
    </b-row>
    <b-row class="HideDesk" v-if="$route.path === '/about-us/history-of-our-sport/hall-of-fame'">
      <b-col cols="12">
        <b-input-group>
          <b-form-select
            v-model="selectedType"
            :options="options"
          ></b-form-select>
        </b-input-group>
      </b-col>
    </b-row>
    <template 
      v-if="page[0].acf.right_sidebar === true || 
      page[0].acf.faq === true || 
      page[0].acf.club_finder_banner === true || 
      page[0].acf.health_section === true || 
      page[0].acf.news_letter_sign_up === true || 
      page[0].acf.useful_links === true"
    >
      <b-row v-if="$route.path === '/about-us/history-of-our-sport/hall-of-fame'" class="hallFameRow">
        <b-col lg="3" v-for="(person, index) in computed_items" :key="index" class="marginBtn-hall">
          <b-nav-item class='hof' :to=" $route.fullPath + '/' + person.slug">
            <div>
              <div class="outerImage">
                <img :src="person.acf.image">
              </div>
            </div>
            <b-card
              :title="person.acf.title"
              img-alt="Image"
              img-top
              tag="article"
              class="mb-2"
            >
            </b-card>
          </b-nav-item>
        </b-col>
      </b-row>
    </template>
    <template v-else-if="$route.path === '/about-us/history-of-our-sport/hall-of-fame'">
      <b-row v-if="$route.path === '/about-us/history-of-our-sport/hall-of-fame'" class="hallFameRow">
        <b-col lg="4" v-for="(person, index) in computed_items" :key="index" class="marginBtn-hall">
          <b-nav-item class='hof' :to="'/about-us/history-of-our-sport/hall-of-fame/' + person.slug">
            <div>
              <div class="outerImage">
                <img :src="person.acf.image">
              </div>
            </div>
            <b-card
              :title="person.acf.title"
              img-alt="Image"
              img-top
              tag="article"
              class="mb-2"
            >
            </b-card>
          </b-nav-item>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row class="hallFameRow">
        <b-col lg="4" v-for="(person, index) in computed_items" :key="index" class="marginBtn-hall">
          <b-nav-item class='hof' :to=" '/about-us/history-of-our-sport/hall-of-fame/' + person.slug">
            <div>
              <div class="outerImage">
                <img :src="person.acf.image">
              </div>
            </div>
            <b-card
              :title="person.acf.title"
              img-alt="Image"
              img-top
              tag="article"
              class="mb-2"
            >
            </b-card>
          </b-nav-item>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "hallOfFame",
  data() {
    return {
      page: [],
      fame: [],
      pick: "",
      selectedType:"",
      options: [
        { value: "", text: "All" },
        { value: "swimming", text: "Swimming" },
        { value: "para-swimming", text: "Para Swimming" },
        { value: "diving", text: "Diving" },
        { value: "open-water", text: "Open Water" },
        { value: "water-polo", text: "Water Polo" },
        { value: "masters", text: "Masters" },
        { value: "artistic-swimming", text: "Artistic Swimming" },
      ],
    };
  },
  computed: {
    computed_items: function () {
      let filterType= this.selectedType
      return this.fame.filter(function(person){
        let filtered = true
        if(filterType && filterType.length > 0){
          filtered = person.acf.discipline_select == filterType
        }
        return filtered
      })
    }
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.wpInfo("wp/v2/pages?slug=hall-of-fame")
      this.page = first_call.data;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  },
  created() {
    apiCall
      .wpInfo("wp/v2/hall-of-fame?per_page=50")
      .then(({ data }) => {
        this.fame = data;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
.hallFameRow {
  margin-top: 100px;
}
.marginBtn-hall {
  margin-bottom: 100px;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  height: 160px;
}
.outerImage {
  height: 130px;
  width: 130px;
  background: #ececec;
  border-radius: 100px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: -65px;
  z-index: 9;
  left: 0;
  margin: auto;
}
li.nav-item.hof > a{
  /* background-color: pink; */
}
::v-deep .card-title {
  font-size: 17px !important;
  color: black;
  font-weight: 600;
  text-align: center;
  margin-top: 70px;
}
::v-deep .card-title:hover {
  font-size: 17px !important;
  color: #009fe3;
  font-weight: 600;
  text-align: center;
  margin-top: 70px;
}
.card {
  border: 0;
  background-color: #e0f5ff;
}
.hof>a{
  padding: 0; 
}
::v-deep .custom-select {
  height: 38px;
  margin-bottom: 30px;
  color: #201546;
  background-color: #e8e7ec;
  border-color: #e8e7ec!important;
  border-radius: 0px;
  text-align: center;
  margin-top: 30px;
}
@media only screen and (min-width : 991px) {
  .HideDesk {
    display: none;
  }
}
@media only screen and (max-width : 990px) {
  .card-body {
    padding-left: 0px;
    padding-bottom: 5px;
  }
  .HideMob {
    display: none;
  }
}
</style>
