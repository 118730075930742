<template>
  <b-row class="removeLine">
    <!-- Ads -->
    <template v-if="type === 'ad'">
      <b-col class="disCol">
        <b-nav-item :href="url" target="_blank">
          <div style="position:relative;">
            <b-img :src="image"></b-img>
          </div>
        </b-nav-item>
      </b-col>
    </template>
    <!-- End Ads -->

    <!-- Downloads PDF -->
    <template v-if="type === 'pdf' && title !== ''">
      <b-col>
        <b-nav-item class="linkText" :href="url" target="_blank">
          <span class="titleLink">{{ title }}</span>
        </b-nav-item>
      </b-col>
    </template>
    <!-- End Downloads PDF -->

    <!-- Links -->
    <template v-if="type === 'link'">
      <b-col>
        <b-nav-item :href="url" target="_blank" class="linkText-Link">
          {{ title }}
        </b-nav-item>
      </b-col>
    </template>
    <!-- End Links -->
  </b-row>
</template>

<script>
export default {
  name: "sidebarBlock",
  props: ["image", "title", "url", "type"],
};
</script>

<style scoped>
li.nav-item.linkText {
  line-height: 18px;
}
li.nav-item.linkText-Link {
  line-height: 30px;
}
hr.hrLine {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  border-top: 1px solid #1f1545;
}
li {
  list-style: disc;
  color: #009fe3;
  /* line-height: 18px; */
}
span.titleLink {
  padding-left: 20px;
  text-align: left;
  display: block;
  margin-top: -20px;
}
svg.icon.svg-inline--fa.fa-arrow-right.fa-w-14 {
  -ms-transform: rotate(-50deg);
  transform: rotate(-50deg);
}
.linkText > a {
  color: #201546!important;
}
.disCol {
  padding: 0 5px 50px 5px;
}
.disCol > li {
  list-style: none;
}
.titleSide {
  height: 66px;
  /* width: 335px; */
  border-radius: 0px 33px 33px 0px;
  font-size: 15px;
  color: #fff;
  margin-top: -33px;
  position: absolute;
  padding: 20px;
  text-transform: uppercase;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  display: flex;
  align-items: center;
}

.down {
  width: 10px;
  right: 37px;
  /* position: absolute; */
}
.nav-item > a {
  padding-left: 0;
  color: #1f1545;
  font-size: 13px;
  font-weight: bold;
  text-decoration: underline;
  display: inline;
}
.disCol > .nav-item > a {
  padding-left: 0.5rem;
}
.db {
  line-height: 1;
  padding-right: 30px;
  font-size: 10pt;
}
</style>
